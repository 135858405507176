import i18next from "i18next";
import TableHeader from "../../../../../../../Admin/components/TableHeader";
import MemberInfo from "../../../../../../../components/MemberRow/MemberInfo";
import { CustomDropdown } from "../../../../../../../components/ui-components";
import DetailsContentHeader from "../DetailsContentHeader";
import styles from "./StatusContacts.module.scss";

export const SORT_TITLES = [
  { title: i18next.t("dashboard:global:name"), key: "name" },
  { title: i18next.t("dashboard:global:role"), key: "role" },
];

export default function StatusContacts() {
  return (
    <>
      <DetailsContentHeader title='Contacts' />
      <TableHeader titles={SORT_TITLES} wrapperStyles={{ gridTemplateColumns: "1fr 125px" }} />
      <div className={styles.list}>
        <div className={styles.listItem}>
          <div>
            <MemberInfo
              avatarSize={40}
              signatory
              hideFlags
              firstName='Indica'
              lastName='Doe'
              company='aivo Revisions AG'
              signatoryStatus='pending'
            />
          </div>
          <div>
            <CustomDropdown style={{ width: 160 }} />
          </div>
        </div>
      </div>
    </>
  );
}
