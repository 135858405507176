import { useEffect, useState } from "react";

import { getCommercialRegister } from "../../../../api/companyApi";

import { LogoPlaceholder } from "../../../../assets/icons";
import { ReactComponent as OpenLinkIcon } from "../../../../assets/icons/open-link.svg";
import { formatCompanyId } from "../../../../utils/general";

import styles from "./CompanyWrapper.module.scss";

const Logo = ({ image }) => {
  return image ? <img src={image} className={styles.logo} alt='' /> : <LogoPlaceholder />;
};

const CompanyWrapper = ({ children, companyData, postfix }) => {
  const [commercialUrl, setCommercialUrl] = useState("");

  useEffect(() => {
    getCommercialRegister(companyData.id).then((res) => {
      setCommercialUrl(res.url);
    });
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.companyInfo}>
        <Logo image={companyData.logo} />
        <div className={styles.infoBlock}>
          <div className={styles.row}>
            <span className={styles.companyTitle}>{companyData.name}</span>
            {postfix}
          </div>
          <span className={styles.companyUID}>
            {companyData.UID && companyData.UID !== "undefined" && formatCompanyId(companyData.UID)}
            {commercialUrl && (
              <a className={styles.link} href={commercialUrl} target='_blank'>
                <OpenLinkIcon />
              </a>
            )}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CompanyWrapper;
