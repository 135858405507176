import React, { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { superadminCopyInviteLink } from "../../../../../../api/superadminApi";
import { COLORS } from "../../../../../../consts/enums";

import { Tag } from "../../../../../../components/ui-components";
import DropDownOptions from "../../../../../../components/DropDownOptions";
import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";

import { ThreeDotsIcon } from "../../../../../../assets/icons";

import { formatPhoneNumberIntl } from "react-phone-number-input";
import styles from "./UserRow.module.scss";

const TYPE_COLORS = {
  audit_firm: COLORS.DARK,
  fiduciary: COLORS.GREEN,
  client: COLORS.BLUE,
};

// const TEAM_TYPES = {
//   audit_firm: "Auditor",
//   fiduciary: "Partner",
//   client: "Client",
// };

const UserRow = ({
  id,
  logo,
  firstName,
  lastName,
  role,
  email,
  phone,
  lastActive,
  platformTeams,
  zIndex,
  teamType,
  companyMemberships,
  setToggleSuperadminPopup,
  setDeleteUserPopup,
  confirmToggleSuperAdmin,
  setEditUser,
  homeTeam,
}) => {
  const [openDrop, setOpenDrop] = useState(null);
  const { t } = useTranslation("admin", { keyPrefix: "users" });
  const name = `${firstName} ${lastName}`;
  const date = lastActive && dayjs(lastActive).format("D MMMM YYYY, HH:mm");

  const handleToggleSuperAdmin = async () => {
    if (role === "super-admin") {
      confirmToggleSuperAdmin({ id, role }, () => setOpenDrop(false));
    } else {
      setToggleSuperadminPopup({
        id,
        name: firstName || lastName ? name : email,
        role,
      });
    }
  };

  const copyInviteLink = async () => {
    try {
      const res = await superadminCopyInviteLink(id);
      await navigator.clipboard.writeText(res.url);
      setOpenDrop(false);
    } catch (e) {
      console.log(e);
    }
  };

  const actionOptions = [
    ...(date
      ? [
          {
            label: t("edit_user"),
            onClick: () =>
              setEditUser({
                id,
                firstName,
                lastName,
                email,
                phone,
                role,
                homeTeamId: homeTeam?.id,
                platformTeams,
              }),
          },
        ]
      : [
          {
            label: t("copy_invite_link"),
            onClick: copyInviteLink,
          },
        ]),
    {
      label: role === "super-admin" ? t("remove_super_admin") : t("make_super_admin"),
      onClick: handleToggleSuperAdmin,
      caution: true,
      // disabled: true,
    },
    {
      label: t("delete_user"),
      onClick: () => setDeleteUserPopup(email),
      caution: true,
    },
  ];

  const classes = [styles.root, !lastActive && styles.inactive, "superadmin-user-row"].join(" ");
  const team = platformTeams?.find((i) => i.isHomeTeam) || platformTeams?.[0];
  const teamName = team?.name;

  return (
    <Link to={`/admin/users`} style={{ zIndex }} className={classes}>
      <div className={styles.row}>
        <CustomAvatar user={{ logo, firstName, email, lastActive }} height={24} width={24} />
        <div className={styles.rowWrap}>
          <span className={styles.name}>{!!name.trim() ? name : email}</span>
          {role === "super-admin" && <span className={styles.flag}>Superadmin</span>}
        </div>
        {/*{teamType && <span className={styles.flag}>{TEAM_TYPES[teamType]}</span>}*/}
      </div>
      <div style={{ gap: 6 }} className={styles.row}>
        {teamType ? (
          <Tag color={TYPE_COLORS[teamType]}>{teamName}</Tag>
        ) : (
          companyMemberships?.length > 0 && (
            <>
              <Tag color={"blue"}>{companyMemberships[0].company.name}</Tag>
              {companyMemberships.length > 1 && (
                <Tag color={"blue"}>{`+${companyMemberships.length - 1}`}</Tag>
              )}
            </>
          )
        )}
      </div>
      <a href={`mailto:${email}`} className={styles.email}>
        {email}
      </a>
      <a href={`tel:${phone}`} className={styles.phone}>
        {formatPhoneNumberIntl(phone)}
        {/*{phone}*/}
      </a>
      <span className={styles.date}>{date || "Not activated"}</span>
      <div className={styles.actions}>
        <ThreeDotsIcon
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            if (openDrop === id) {
              setOpenDrop(null);
            } else {
              setOpenDrop(id);
            }
          }}
        />
      </div>
      {openDrop === id && <DropDownOptions options={actionOptions} handleClose={setOpenDrop} />}
      <hr />
    </Link>
  );
};

export default UserRow;
