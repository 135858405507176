import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { attachEntities } from "../../../../../../../api/statusApi";
import toFullName from "../../../../../../../consts/toFullName";
import { setMeetingData, setRequestData } from "../../../../../../../store/global/actions";

import SelectEntity from "../../../../../../../Admin/pages/components/SelectEntity/SelectEntity";
import RequestBlock from "../../../../../../../Admin/pages/Requests/components/RequestsList/RequestBlock/RequestBlock";
import MeetingBlock from "../../../../../Meetings/components/MeetingsList/MeetingBlock/MeetingBlock";
import AttachmentRow from "../../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";

import styles from "../../StatusView.module.scss";

const ClientContent = ({
  openedStep,
  setOpenedStep,
  idAudit,
  requests,
  meetings,
  documents,
  updateCurrentStepData,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });

  const handleSelectRequest = async (name, request) => {
    if (request.type === "quote") {
      setOpenedStep({
        ...openedStep,
        attachedQuoteRequest: request,
        attachedQuoteRequestId: request.id,
      });
      updateCurrentStepData({
        attachedQuoteRequest: request,
        attachedQuoteRequestId: request.id,
      });
      await attachEntities(idAudit, openedStep.id, { attachedQuoteRequest: request.id });
    } else {
      const newRequests = [...(openedStep.attachedRequests || []), request];
      setOpenedStep({ ...openedStep, attachedRequests: newRequests });
      updateCurrentStepData({ attachedRequests: newRequests });
      await attachEntities(idAudit, openedStep.id, {
        attachedRequests: newRequests.map((i) => i.id),
      });
    }
  };

  const handleRemoveRequest = async (name, request) => {
    if (request.type === "quote") {
      setOpenedStep({ ...openedStep, attachedQuoteRequest: null, attachedQuoteRequestId: null });
      updateCurrentStepData({ attachedQuoteRequest: null, attachedQuoteRequestId: null });
      await attachEntities(idAudit, openedStep.id, { attachedQuoteRequest: null });
    } else {
      const newRequests = openedStep.attachedRequests.filter((i) => i.id !== request.id);
      setOpenedStep({
        ...openedStep,
        attachedRequests: newRequests,
      });
      updateCurrentStepData({ attachedRequests: newRequests });
      await attachEntities(idAudit, openedStep.id, {
        attachedRequests: newRequests.map((i) => i.id),
      });
    }
  };

  const handleSelectEntity = async (name, entity) => {
    const newEntities = [...openedStep[name], entity];
    setOpenedStep({ ...openedStep, [name]: newEntities });
    updateCurrentStepData({ [name]: newEntities });
    await attachEntities(idAudit, openedStep.id, { [name]: newEntities.map((i) => i.id) });
  };

  const handleRemoveEntity = async (name, entity) => {
    const newEntities = openedStep[name].filter((i) => i.id !== entity.id);
    setOpenedStep({ ...openedStep, [name]: newEntities });
    updateCurrentStepData({ [name]: newEntities });
    await attachEntities(idAudit, openedStep.id, { [name]: newEntities.map((i) => i.id) });
  };

  return (
    <>
      {openedStep.type === "request" && (
        <div className={styles.field}>
          <label>{t("attach_request")}</label>
          <SelectEntity
            disabled={openedStep.status === "done"}
            name={"attachedRequests"}
            values={[
              ...openedStep.attachedRequests,
              { ...openedStep.attachedQuoteRequest, type: "quote" },
            ].filter((i) => i.id)}
            onChange={handleSelectRequest}
            onRemove={handleRemoveRequest}
            dropdownLabel={t("latest_requests")}
            placeholder={t("search_request")}
            url={`/dashboard/${idAudit}/requests/`}
            cleanUpAction={setRequestData}
            options={requests}
            optionComponent={({ comments, status, name, id, type, relatedDocs, request_type }) => (
              <RequestBlock
                titleStyle={{ maxWidth: "unset" }}
                bordered
                openRequest={() => {}}
                status={status}
                commentsCount={comments?.length}
                relatedDocsCount={relatedDocs?.length}
                name={name}
                itemId={id}
                requestType={type || request_type}
                key={id}
              />
            )}
          />
        </div>
      )}
      {openedStep.type === "meeting" && (
        <div className={styles.field}>
          <label>{t("attach_meeting")}</label>
          <SelectEntity
            disabled={openedStep.status === "done"}
            name={"attachedMeetings"}
            values={openedStep.attachedMeetings || []}
            onChange={handleSelectEntity}
            onRemove={handleRemoveEntity}
            dropdownLabel={t("latest_meetings")}
            placeholder={t("search_meetings")}
            url={`/dashboard/${idAudit}/meetings/`}
            cleanUpAction={setMeetingData}
            options={meetings}
            optionComponent={({
              id,
              title,
              organiser,
              platform,
              date,
              startTime,
              assignedUsers,
              assignedRoles,
            }) => (
              <MeetingBlock
                titleStyle={{ maxWidth: "unset" }}
                platform={platform}
                date={date}
                startTime={startTime}
                creatorFullName={toFullName(organiser)}
                assignedUsers={assignedUsers}
                assignedRoles={assignedRoles}
                openMeeting={() => {}}
                name={title}
                itemId={id}
                key={id}
                bordered
              />
            )}
          />
        </div>
      )}
      {openedStep.type === "signature" && (
        <div className={styles.field}>
          <label>{t("attach_document")}</label>
          <SelectEntity
            disabled={openedStep.status === "done"}
            name={"attachedDocuments"}
            values={openedStep.attachedDocuments}
            onChange={handleSelectEntity}
            onRemove={handleRemoveEntity}
            dropdownLabel={t("latest_legal_documents")}
            placeholder={t("search_document")}
            url={`/dashboard/${idAudit}/documents/`}
            options={documents}
            optionComponent={(document) => (
              <AttachmentRow
                key={document.id}
                file={document}
                onClick={(e) => e?.preventDefault()}
                // onClick={cleanRequestData}
                // deleteDocument={handleRemove(value)}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ClientContent;
