import orderBy from "lodash/orderBy";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFetch } from "../../../../hooks";
import { superadminGetCompanies } from "../../../../api/superadminApi";
import { SORT_TITLES } from "./Companies.constants";

import Tabs from "../../../../components/ui-components/Tabs/Tabs";
import SearchInput from "../../../../components/ui-components/SearchInput/SearchInput";
import TableHeader from "../../../components/TableHeader";
import { Loading } from "../../../../components/ui-components";
import CompanyRow from "./components/CompanyRow/CompanyRow";

import SuperadminAddCompany from "../../../../components/SuperadminAddCompany";
import PlatformAdminTitleBlock from "../../components/PlatformAdminTitleBlock";

import styles from "./Companies.module.scss";

const tabs = ["all", "audit_firm", "fiduciary", "client"];

const Companies = () => {
  const { t } = useTranslation("admin", { keyPrefix: "companies" });

  const [tab, setTab] = useState("all");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState();
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false);

  const { isLoading, data, refetch } = useFetch(superadminGetCompanies);

  const filteredCompanies = useMemo(() => {
    const searchLower = search.toLowerCase();
    let filtered = data?.filter((company) => {
      const userMatch = company && company.name.toLowerCase().includes(searchLower);

      switch (tab) {
        case "audit_firm":
          return company.type === "audit_firm" && userMatch;
        case "fiduciary":
          return company.type === "fiduciary" && userMatch;
        case "client":
          return company.type === "client" && userMatch;
        default:
          return userMatch;
      }
    });

    if (filters) {
      filtered = orderBy(filtered, filters.order, filters.orderBy);
    }

    return filtered?.reduce((acc, item) => {
      if (item.type === "client") {
        item.accountAmount = item.members.length;
      } else {
        item.accountAmount = item.roles.reduce((acc, role) => acc + role.members.length, 0);
      }

      if (search && !item.name.toLowerCase().includes(search.toLowerCase())) {
        return acc;
      }

      if (tab === "all" || item.type === tab) {
        return [...acc, item];
      }

      return acc;
    }, []);
  }, [data, tab, search, filters]);

  useEffect(() => {
    refetch();
  }, []);

  const onAddCompanySuccess = () => {
    setIsOpenCreatePopup(false);
    refetch();
  };

  const handleOpenPopup = () => {
    setIsOpenCreatePopup(true);
  };

  return (
    <>
      <SuperadminAddCompany
        isOpen={isOpenCreatePopup}
        onSuccess={onAddCompanySuccess}
        close={setIsOpenCreatePopup}
      />
      <PlatformAdminTitleBlock onAdd={handleOpenPopup} />
      <div className={styles.actions}>
        <Tabs value={tab} onClick={setTab} options={tabs} t={t} />
        <SearchInput value={search} onChange={setSearch} placeholder={t("search_companies")} />
      </div>
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "minmax(130px, 1fr) 100px 100px 150px 100px" }}
        filters={filters}
        onChange={setFilters}
      />
      {!isLoading ? (
        filteredCompanies?.map((item, index) => <CompanyRow {...item} key={index} />)
      ) : (
        <Loading className={styles.loader} />
      )}
    </>
  );
};

export default Companies;
