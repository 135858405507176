import orderBy from "lodash/orderBy";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { Voucher } from "../../../../types/vouchers";
import { fetchVouchers } from "../../../../services/voucherService";

import SearchInput from "../../../../components/ui-components/SearchInput/SearchInput";
import { Loading } from "../../../../components/ui-components";
import VouchersRow from "./componsnts/VouchersRow";
import TableHeader, { TableHeaderProps } from "../../../components/TableHeader";
import Tabs from "../../../../components/ui-components/Tabs/Tabs";
import CreateVoucherPopup from "./componsnts/CreateVoucherPopup";
import PlatformAdminTitleBlock from "../../components/PlatformAdminTitleBlock";

import { SORT_TITLES, VOUCHERS_TABS } from "./Vouchers.constants";
import styles from "./Vouchers.module.scss";

export default function Vouchers() {
  const { data: vouchers = [], isLoading } = useQuery<Voucher[], Error>({
    queryKey: ["vouchers"],
    queryFn: fetchVouchers,
    staleTime: 5 * 60 * 1000,
  });

  const { t } = useTranslation("admin", { keyPrefix: "vouchers" });

  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false);
  const [tab, setTab] = useState(VOUCHERS_TABS[0]);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<TableHeaderProps["filters"]>();

  const filteredVouchers = useMemo(() => {
    const searchLower = search.toLowerCase();
    let filtered = vouchers.filter(({ code, user, used }) => {
      const userMatch =
        user &&
        (user.firstName.toLowerCase().includes(searchLower) ||
          user.lastName.toLowerCase().includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower));

      const codeMatch = code.toLowerCase().includes(searchLower);

      switch (tab) {
        case "used":
          return !!used && (userMatch || codeMatch);
        case "unused":
          return !used && (userMatch || codeMatch);
        default:
          return userMatch || codeMatch;
      }
    });

    if (filters) {
      filtered = orderBy(filtered, filters.order, filters.orderBy);
    }

    return filtered;
  }, [vouchers, tab, search, filters]);

  const handleOpenCreatePopup = () => {
    setIsOpenCreatePopup(true);
  };

  const handleCloseCreatePopup = () => {
    setIsOpenCreatePopup(false);
  };

  return (
    <>
      <PlatformAdminTitleBlock onAdd={handleOpenCreatePopup} />
      <div className={styles.actions}>
        <Tabs value={tab} onClick={setTab} options={VOUCHERS_TABS} t={t} />
        <SearchInput value={search} onChange={setSearch} placeholder={t("search_vouchers")} />
      </div>
      <TableHeader
        wrapperStyles={{ gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) 200px 80px 32px" }}
        titles={SORT_TITLES}
        filters={filters}
        onChange={setFilters}
      />
      {isLoading ? (
        <Loading className={styles.loader} />
      ) : (
        filteredVouchers.map((voucher) => <VouchersRow key={voucher.id} {...voucher} />)
      )}

      <CreateVoucherPopup isOpen={isOpenCreatePopup} onClose={handleCloseCreatePopup} />
    </>
  );
}
