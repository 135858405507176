import { Tabs } from "antd";
import clsx from "clsx";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderAdmin from "../../components/HeaderAdmin";
import Companies from "./Companies";
import Users from "./Users";
import Vouchers from "./Vouchers";
import ManageTranslations from "./ManageTranslations";

import styles from "./PlatformAdmin.module.scss";
import { useEffect } from "react";
import { superadminGetPresets } from "../../../api/superadminApi";

const BASE_URL = "/admin";
const tabs = ["companies", "users", "vouchers", "manage-translations"];

interface RouteParams {
  path: string;
}

const PlatformAdmin = () => {
  const history = useHistory();
  const { path } = useParams<RouteParams>();
  const { t } = useTranslation("admin", { keyPrefix: "common" });

  const handleChangeTab = (key: string) => {
    history.push(`${BASE_URL}/${key}`);
  };

  return (
    <div className={styles.wrapper}>
      <HeaderAdmin />
      <div
        className={clsx(styles.content, {
          [styles.fluid]: window.location.pathname.includes("/admin/manage-translations"),
        })}
      >
        <Tabs
          items={tabs.map((tab) => ({ key: tab, label: t(tab) }))}
          activeKey={path}
          defaultActiveKey={path || tabs[0]}
          onChange={handleChangeTab}
        />
        <Switch>
          <Route path={`${BASE_URL}/companies`} component={Companies} />
          <Route path={`${BASE_URL}/users`} component={Users} />
          <Route path={`${BASE_URL}/vouchers`} component={Vouchers} />
          <Route path={`${BASE_URL}/manage-translations`} component={ManageTranslations} />
        </Switch>
      </div>
    </div>
  );
};

export default PlatformAdmin;
