import i18next from "i18next";

export const SORT_TITLES = [
  { title: i18next.t("dashboard:documents:table_header_name"), key: "name" },
  { title: i18next.t("dashboard:documents:table_header_owner"), key: "addedBy.firstName" },
  { title: i18next.t("dashboard:documents:table_header_shared_with"), key: "type" },
  { title: i18next.t("dashboard:documents:table_header_signatures"), key: "status" },
  { title: i18next.t("dashboard:documents:table_header_added"), key: "addedAt" },
];

export const categoryOptions = ["evidence", "legal", "shared"];
