import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableHeader from "../../../../../../../Admin/components/TableHeader";
import EditDocument from "../../../../../../../components/EditDocument";
import NewDocument from "../../../../../../../components/NewDocument";

import { Document } from "../../../../../../../types/document";
import DocumentRow from "../../../../../Documents/components/DocumentRow";
import DetailsContentHeader from "../DetailsContentHeader";

const SORT_TITLES = [
  { title: i18next.t("dashboard:status:risk_table_header_name"), key: "name" },
  { title: i18next.t("dashboard:status:risk_table_header_signature"), key: "signature" },
];

type Props = {
  documents: Document[];
};

export default function StatusDocuments({ documents }: Props) {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [addNewDocument, setAddNewDocument] = useState(false);
  const [editDoc, setEditDoc] = useState<Document | null>(null);

  return (
    <>
      <DetailsContentHeader onUpload={() => setAddNewDocument(true)} title={tGlobal("documents")} />
      <TableHeader titles={SORT_TITLES} wrapperStyles={{ gridTemplateColumns: "1fr 150px 35px" }} />
      <div>
        {documents.map((d: Document) => (
          <DocumentRow
            key={d.id}
            document={d}
            hideDownload
            style={{ gridTemplateColumns: "1fr 150px 35px" }}
            renderFields={["name", "signatures"]}
            onEdit={() => setEditDoc(d)}
          />
        ))}
      </div>
      {addNewDocument && <NewDocument activeTab='all' close={setAddNewDocument} />}
      {editDoc && (
        <EditDocument
          id={editDoc.id}
          docName={editDoc.name}
          docFile={editDoc.document}
          docType={editDoc.type}
          requiresSignature={editDoc.requiresSignature}
          closeDrop={setEditDoc}
          assigners={editDoc.signers}
        />
      )}
    </>
  );
}
