import { Space } from "antd";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TableHeader from "../../../../../../../Admin/components/TableHeader";
import { Button } from "../../../../../../../components/ui-components";
import DetailsContentHeader from "../DetailsContentHeader";

const SORT_TITLES = [
  { title: i18next.t("dashboard:global:type"), key: "type" },
  { title: i18next.t("dashboard:global:description"), key: "description" },
  { title: i18next.t("dashboard:global:assertion"), key: "assertion" },
];

export default function SimilarTasks() {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [type, setType] = useState<"all" | "core" | "additional">("all");

  return (
    <>
      <DetailsContentHeader
        title='Similar tasks'
        hasFilters={false}
        extraFilters={
          <Space>
            <Button
              small
              primary
              color={type === "all" ? "dark" : "gray"}
              onClick={() => setType("all")}
            >
              {tGlobal("all")}
            </Button>
            <Button
              small
              primary
              color={type === "core" ? "dark" : "gray"}
              onClick={() => setType("core")}
            >
              {t("core")}
            </Button>
            <Button
              primary
              color={type === "additional" ? "dark" : "gray"}
              small
              onClick={() => setType("additional")}
            >
              {t("additional")}
            </Button>
          </Space>
        }
      />
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "100px 1fr 130px" }}
      />
      <div></div>
    </>
  );
}
