import { ContentBlock, ContentState, EditorState } from "draft-js";
import { useTranslation } from "react-i18next";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as DecreaseIcon } from "../../../../../assets/icons/textEditor/indent-decrease.svg";
import { ReactComponent as IncreaseIcon } from "../../../../../assets/icons/textEditor/indent-increase.svg";

type IndentButtonProps = {
  type: "increase" | "decrease";
} & TextEditorButtonProps;

const IndentButton = ({ type, editorState, onEditorStateChange }: IndentButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const selectedBlocks = blockMap
    .toSeq()
    .skipUntil((_, key) => key === startKey)
    .takeUntil((_, key) => key === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .toMap();

  const isListBlock = (blockType: string) =>
    ["ordered-list-item", "unordered-list-item"].includes(blockType);

  const toggleStyle = () => {
    let newContentState = contentState;

    selectedBlocks.forEach((block) => {
      if (!block) return; // Ensure block is defined

      const blockDepth = block.getDepth();
      const blockType = block.getType();

      if (!isListBlock(blockType)) return;

      const adjustment = type === "increase" ? 1 : -1;
      const newDepth = blockDepth + adjustment;

      if (newDepth >= 0 && newDepth <= 2) {
        const newBlock = block.set("depth", newDepth) as ContentBlock;
        newContentState = newContentState.merge({
          blockMap: newContentState.getBlockMap().set(block.getKey(), newBlock),
        }) as ContentState;
      }
    });

    const newEditorState = EditorState.push(editorState, newContentState, "change-block-type");
    const editorStateWithFocus = EditorState.forceSelection(
      newEditorState,
      editorState.getSelection()
    );
    onEditorStateChange?.(editorStateWithFocus);
  };

  const allBlocksAreListItems = selectedBlocks.every((block) =>
    block ? isListBlock(block.getType()) : false
  );

  const canIndent =
    allBlocksAreListItems &&
    selectedBlocks.every((block) => {
      if (!block) return false;
      const depth = block.getDepth();
      return type === "increase" ? depth < 2 : depth > 0;
    });

  return (
    <ToolbarButton
      disabled={!canIndent}
      Icon={type === "decrease" ? DecreaseIcon : IncreaseIcon}
      tooltipTitle={t(`indent_${type}`)}
      onClick={toggleStyle}
    />
  );
};

export default IndentButton;
