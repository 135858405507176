import API from "./API.js";

export const auditDocuments = async (id, params) => {
  try {
    const response = await API().get(`/audit/${id}/documents`, {
      params,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllDocuments = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/documents/all`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDocumentBacklinks = async (id, params) => {
  try {
    const response = await API().get(`/audit/${id}/documents/with-backlinks`, {
      params,
    });
    return response.data?.documents;
  } catch (error) {
    return error;
  }
};

export const getDeletedDocuments = async (id, params) => {
  try {
    const response = await API().get(`/audit/${id}/documents/all/deleted`, {
      params,
    });
    return response.data?.documents;
  } catch (error) {
    return error;
  }
};

export const oneDocument = async (id, documentId) => {
  try {
    const response = await API().get(`/audit/${id}/documents/${documentId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifySwisscom = async (id, documentId) => {
  try {
    const response = await API().post(`/audit/${id}/documents/${documentId}/verify-swisscom`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyIdentity = async () => {
  const response = await API().post(`/user/verify-identity`);
  return response.data;
};

export const signDocument = async (id, documentId, body) => {
  try {
    const response = await API().post(`/audit/${id}/documents/${documentId}/sign`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const syncDocument = async (id, documentId) => {
  try {
    const response = await API().get(`/audit/${id}/documents/${documentId}/sync`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addDocument = async ({ body, auditId }) => {
  try {
    const FD = new FormData();
    FD.append("name", body.name);
    FD.append("document", body.document);
    FD.append("type", body.type);
    FD.append("signers", body.signers);

    const response = await API().post(`/audit/${auditId}/documents/`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addMultipleDocuments = async ({ documents, type, auditId }) => {
  try {
    const FD = new FormData();
    documents.forEach((document, idx) => {
      FD.append(`document${idx + 1}`, document);
    });
    FD.append("type", type);

    const response = await API().post(`/audit/${auditId}/documents/multiple`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadShared = async (file, id) => {
  try {
    const FD = new FormData();
    FD.append("file", file);

    const response = await API().post(`/audit/${id}/documents/shared`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addDocumentComment = async (body, id, documentId) => {
  try {
    const response = await API().post(`/audit/${id}/documents/${documentId}/comment`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteDocument = async ({ idAudit, documentId }) => {
  try {
    const response = await API().delete(`/audit/${idAudit}/documents/${documentId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const replaceDocument = async ({ idAudit, documentId, data }) => {
  try {
    const newData = new FormData();
    if (data.file) {
      newData.append("file", data.file);
    }
    if (data.sourceId) {
      newData.append("sourceId", data.sourceId);
    }
    const response = await API().post(`/audit/${idAudit}/documents/${documentId}/replace`, newData);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const downloadDocument = async (id, documentId) => {
  try {
    const response = await API().get(`/audit/${id}/documents/${documentId}/download`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const restoreDeletedDocument = async (id, documentId) => {
  const response = await API().post(`/audit/${id}/documents/${documentId}/restore`);
  return response.data;
};

export const exportDocument = async (idAudit, requestId) => {
  try {
    const response = await API().get(`/bexio/${idAudit}/export/${requestId}`, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/zip",
      },
    });
    const blob = await response.data;
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "Fetched-files.zip";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const editDocuments = async ({ body, auditId, documentId }) => {
  try {
    const FD = new FormData();
    FD.append("name", body.name);
    if (body.signers) {
      FD.append("signers", body.signers);
    }
    if (body.requiresSignature) {
      FD.append("requiresSignature", body.requiresSignature);
    }
    if (body.type) {
      FD.append("type", body.type);
    }
    const response = await API().post(`/audit/${auditId}/documents/${documentId}/update`, FD);
    return response.data?.document;
  } catch (error) {
    return error;
  }
};

export const inviteDocumentGuest = async (body, auditId, documentId) => {
  const response = await API().post(`/audit/${auditId}/documents/${documentId}/invite/guest`, body);
  return response.data;
};

export const inviteDocumentMember = async (body, auditId, documentId) => {
  const response = await API().post(`/audit/${auditId}/documents/${documentId}/invite`, body);
  return response.data;
};

export const updateDocumentAccessSettings = async (body, auditId, documentId) => {
  const response = await API().post(`/audit/${auditId}/documents/${documentId}/access`, body);
  return response.data;
};

export const updateDocumentMemberAccessSettings = async (body, auditId, documentId, userId) => {
  const response = await API().post(
    `/audit/${auditId}/documents/${documentId}/members/${userId}/access`,
    body
  );
  return response.data;
};
