import "./styles/header.css";
import logo from "../assets/images/logo-white.svg";
import Button from "../customComponents/Button/Button";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import useResize from "../hooks/useResize";
import Keys from "../consts/helper";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";

const lang_list = ["en", "de"];

const Header = ({ theme }) => {
  const [activeUrl, setActiveUrl] = useState(null);
  const [showMenu, setShowMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const { innerWidth } = useResize();
  const history = useHistory();
  const { t, i18n } = useTranslation("header");

  // const [activeLang, setActiveLang] = useState('german');

  const updateLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      localStorage.setItem("i18nextLng", lang);
      handleClose();
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (pathname.split("/")[1]) {
      setActiveUrl(pathname.split("/")[1]);
    }
  }, [pathname]);

  const user = JSON.parse(localStorage.getItem(Keys.USER_DATA))?.data;
  const logIn = () => {
    user?.token ? history.push("/audits") : history.push("/login");
  };

  return (
    <header className={`header-aivo header-aivo-${theme}`}>
      {/*<div className="header_info">*/}
      {/*  <div className="header_i">*/}
      {/*    <div className="header_info-left">*/}
      {/*      <span*/}
      {/*        onClick={() => {*/}
      {/*          setActiveLang('german');*/}
      {/*        }}*/}
      {/*        className={`${activeLang === 'german' ? 'lang-active' : ''}`}>*/}
      {/*        German*/}
      {/*      </span>*/}
      {/*      <span*/}
      {/*        onClick={() => {*/}
      {/*          setActiveLang('english');*/}
      {/*        }}*/}
      {/*        className={`${activeLang === 'english' ? 'lang-active' : ''}`}>*/}
      {/*        English*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*    <div className="header_info-right">*/}
      {/*      <span>info@aivo.ch</span>*/}
      {/*      <span>079 916 46 27</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={"wrapper container"}>
        <div className='menu'>
          <Link to={"/"} className={"logo"} onClick={() => setActiveUrl(null)}>
            {theme === "gray" ? (
              <svg
                width='71'
                className={"logo"}
                height='25'
                viewBox='0 0 71 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M47.6884 6.00824C48.215 6.21344 48.5852 6.56632 48.7958 7.06688C49.0064 7.56744 49.0064 8.07421 48.7958 8.58876C47.8464 11.003 46.8778 13.4684 45.8901 15.9852C44.9023 18.502 43.9322 20.9675 42.9843 23.3817C42.879 23.5869 42.662 23.8434 42.3317 24.1528C42.0014 24.4606 41.6136 24.6145 41.1652 24.6145C40.7695 24.6145 40.3881 24.5181 40.0179 24.3253C39.6493 24.1325 39.386 23.8185 39.2265 23.3817L33.3352 8.59032C33.1246 8.07732 33.131 7.56899 33.3544 7.06843C33.5778 6.56787 33.9543 6.21499 34.4809 6.00979L34.441 6.04866C34.9947 5.86833 35.5277 5.88854 36.0431 6.10618C36.5569 6.32536 36.9191 6.69068 37.1298 7.20368L37.0899 7.16481L41.0839 17.3346C41.3471 16.6677 41.6503 15.8904 41.9934 15.0043C42.3365 14.1182 42.6987 13.1995 43.0801 12.2497C43.463 11.2999 43.8316 10.3811 44.1875 9.49506C44.5433 8.60897 44.8529 7.83171 45.1162 7.16481L45.0364 7.20368C45.247 6.69068 45.6156 6.32381 46.1438 6.10618C46.6704 5.88854 47.1985 5.86833 47.7251 6.04866L47.6884 6.00824Z'
                  fill='#49BACA'
                />
                <path
                  d='M10.7134 6.00835C11.899 6.13737 13.0128 6.47004 14.0548 7.00946C15.0952 7.54889 15.992 8.23599 16.7435 9.07078C17.4951 9.90556 18.0871 10.8554 18.5227 11.9218C18.9583 12.9882 19.1753 14.1106 19.1753 15.292V22.7258C19.1753 23.2388 18.9775 23.6818 18.5818 24.0549C18.186 24.428 17.7376 24.613 17.2382 24.613C17.0531 24.613 16.8488 24.6006 16.6254 24.5741C16.402 24.5477 16.1834 24.4778 15.9728 24.3627C15.7622 24.2477 15.5707 24.086 15.4 23.8808C15.2292 23.6756 15.1303 23.3927 15.1032 23.0336C14.9707 23.0849 14.7346 23.1999 14.3915 23.3803C14.0484 23.5606 13.7979 23.6881 13.6399 23.7658C13.3495 23.8948 13.0463 24.0098 12.7304 24.1124C12.4144 24.215 12.0841 24.3176 11.7426 24.4202C11.0836 24.5493 10.3719 24.613 9.60762 24.613C7.63056 24.613 5.86414 24.0922 4.30995 23.0522C2.75415 22.0123 1.59409 20.6707 0.829758 19.0276C0.56647 18.4633 0.362223 17.8663 0.217016 17.2367C0.0718083 16.6072 0 15.9589 0 15.292C0 14.6251 0.0718083 13.9815 0.217016 13.366C0.362223 12.7504 0.56647 12.1456 0.829758 11.5549C1.59409 9.88535 2.75415 8.53757 4.30995 7.51002C5.86574 6.48248 7.63056 5.96948 9.60762 5.96948C9.81825 5.96948 10.0033 5.96948 10.1613 5.96948C10.3177 5.96948 10.5028 5.98192 10.7134 6.00835ZM9.56772 20.8386C10.3321 20.8386 11.0645 20.6971 11.7618 20.4142C12.4607 20.1313 13.0671 19.7395 13.5809 19.239C14.0947 18.7384 14.5032 18.1539 14.8064 17.487C15.1095 16.8186 15.2611 16.0879 15.2611 15.292C15.2611 14.5723 15.1095 13.8727 14.8064 13.1934C14.5032 12.5125 14.0883 11.9218 13.5617 11.4212C13.0351 10.9207 12.4288 10.5165 11.7426 10.2071C11.0581 9.89934 10.3321 9.74544 9.56772 9.74544C8.80339 9.74544 8.08533 9.89312 7.41355 10.1885C6.74177 10.4838 6.14179 10.888 5.61521 11.4026C5.08864 11.9156 4.67217 12.5063 4.36899 13.1747C4.06581 13.8416 3.91421 14.549 3.91421 15.2936C3.91421 16.0646 4.07219 16.7828 4.38813 17.4513C4.70408 18.1197 5.13332 18.7027 5.67266 19.2048C6.21359 19.7053 6.81996 20.1033 7.49174 20.3987C8.16352 20.6909 8.85605 20.8386 9.56772 20.8386Z'
                  fill='#003E47'
                />
                <path
                  d='M28.2134 2.00224C28.2134 2.56653 28.0155 3.04844 27.6198 3.4464C27.2241 3.84436 26.7501 4.04334 26.1964 4.04334C25.6172 4.04334 25.1226 3.84436 24.7141 3.4464C24.3056 3.04844 24.1013 2.56653 24.1013 2.00224C24.1013 1.46282 24.3056 0.994901 24.7141 0.596941C25.1226 0.19898 25.6172 0 26.1964 0C26.7501 0 27.2241 0.19898 27.6198 0.596941C28.0155 0.993347 28.2134 1.46282 28.2134 2.00224ZM26.1964 24.6129C25.6172 24.6129 25.1226 24.4202 24.7141 24.0346C24.3056 23.6491 24.1013 23.161 24.1013 22.5703V8.08824C24.1013 7.52394 24.3056 7.04825 24.7141 6.66273C25.1226 6.27721 25.6172 6.08444 26.1964 6.08444C26.723 6.08444 27.1921 6.27721 27.6006 6.66273C28.0091 7.04825 28.2134 7.52239 28.2134 8.08824V22.5718C28.2134 23.1625 28.0091 23.6507 27.6006 24.0362C27.1906 24.4202 26.723 24.6129 26.1964 24.6129Z'
                  fill='#003E47'
                />
                <path
                  d='M61.4707 6.35498C62.7616 6.35498 63.9823 6.60526 65.128 7.10582C66.2753 7.60638 67.2822 8.28105 68.1534 9.12827C69.0231 9.97549 69.7156 10.9642 70.2294 12.0943C70.7432 13.2245 71.0001 14.4308 71.0001 15.7148C71.0001 16.9989 70.7496 18.199 70.2485 19.3167C69.7475 20.4344 69.0693 21.4169 68.2124 22.2641C67.3556 23.1113 66.3471 23.7797 65.187 24.2679C64.027 24.756 62.7743 25.0001 61.4308 25.0001C60.1127 25.0001 58.8745 24.7684 57.7144 24.3067C56.5544 23.845 55.5395 23.1952 54.6699 22.362C53.8002 21.5272 53.1141 20.5448 52.6146 19.4146C52.1136 18.2845 51.863 17.0517 51.863 15.7164C51.863 14.4323 52.1072 13.2322 52.5939 12.1145C53.0821 10.9968 53.7603 10.0221 54.63 9.18734C55.4996 8.35256 56.5209 7.68566 57.6937 7.1851C58.8681 6.68143 60.1271 6.40628 61.4707 6.35498ZM61.4308 10.1683C60.6664 10.1683 59.934 10.3097 59.2367 10.5926C58.5378 10.8756 57.9314 11.2673 57.4176 11.7679C56.9038 12.2684 56.4953 12.8529 56.1921 13.5198C55.889 14.1883 55.7374 14.9189 55.7374 15.7148C55.7374 16.4594 55.889 17.1714 56.1921 17.8523C56.4953 18.5332 56.9038 19.1301 57.4176 19.6431C57.9314 20.1561 58.5378 20.5619 59.2367 20.8572C59.9356 21.1526 60.6664 21.3003 61.4308 21.3003C62.1951 21.3003 62.9275 21.1526 63.6248 20.8572C64.3238 20.5619 64.9301 20.1577 65.4439 19.6431C65.9577 19.1301 66.3662 18.5332 66.6694 17.8523C66.9726 17.1714 67.1242 16.4594 67.1242 15.7148C67.0444 14.1992 66.4923 12.9213 65.4631 11.8813C64.4355 10.8414 63.1031 10.2693 61.4691 10.1667H61.4308V10.1683Z'
                  fill='#003E47'
                />
              </svg>
            ) : (
              <img src={logo} alt={"logo"} />
            )}
          </Link>
          {innerWidth > 1000
            ? [
                <Link
                  to={"/revision"}
                  key={1}
                  style={{
                    "--color": theme === "gray" ? "#003e47" : "#F2F5F6",
                  }}
                  className={`${activeUrl === "revision" ? "nav-bar-active" : ""} nav-bar`}
                >
                  {t("audit")}
                </Link>,
                <Link
                  to={"/team"}
                  key={2}
                  style={{
                    "--color": theme === "gray" ? "#003e47" : "#F2F5F6",
                  }}
                  className={`${activeUrl === "team" ? "nav-bar-active" : ""} nav-bar`}
                >
                  {t("about")}
                </Link>,
                <Link
                  to={"/partner"}
                  key={3}
                  style={{
                    "--color": theme === "gray" ? "#003e47" : "#F2F5F6",
                  }}
                  className={`${activeUrl === "partner" ? "nav-bar-active" : ""} nav-bar`}
                >
                  {t("partner")}
                </Link>,
                <Link
                  to={"/faq"}
                  key={4}
                  style={{
                    "--color": theme === "gray" ? "#003e47" : "#F2F5F6",
                  }}
                  className={`${activeUrl === "faq" ? "nav-bar-active" : ""} nav-bar`}
                >
                  {t("FAQ")}
                </Link>,
                <Link
                  to={"/kontakt"}
                  key={5}
                  style={{
                    "--color": theme === "gray" ? "#003e47" : "#F2F5F6",
                  }}
                  className={`${activeUrl === "kontakt" ? "nav-bar-active" : ""} nav-bar`}
                >
                  {t("contact")}
                </Link>,
              ]
            : null}
        </div>
        <div className='revision-and-login'>
          <div className='lang'>
            <span onClick={(e) => setAnchorEl(e.currentTarget)}>
              {i18n.language.toUpperCase()}
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.8383 6.00006C11.0917 6.00037 11.3201 6.15319 11.417 6.38724C11.5142 6.62162 11.4605 6.891 11.2814 7.07037L8.1758 10.176C8.05892 10.2941 7.89955 10.3604 7.73361 10.3604C7.56736 10.3604 7.40799 10.2941 7.29112 10.176L4.18552 7.07037C4.00489 6.89193 3.9502 6.62224 4.04708 6.38756C4.14364 6.15287 4.37239 5.99974 4.62645 6.00005L10.8383 6.00006Z'
                  fill={activeUrl ? "#003E47" : "white"}
                />
              </svg>
            </span>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {lang_list.map((i) => (
                <MenuItem selected={i18n.language === i} onClick={() => updateLanguage(i)}>
                  {i.toUpperCase()}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {innerWidth > 768
            ? [
                <Link to={`/kontakt/besprechung`} className={"btn_orange btn_default"}>
                  {t("request_audit")}
                </Link>,
                <Button
                  text={t("log_in")}
                  key={1}
                  onClick={() => {
                    logIn();
                  }}
                  className={theme === "gray" ? "btn_blue" : ""}
                />,
              ]
            : null}
          {innerWidth <= 768 ? (
            <div
              className={`${showMenu ? "wrapper_header_burger_active" : ""} wrapper_header_burger`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className='header_burger' />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`${
          innerWidth <= 1000 && showMenu ? "wrapper_open_menu_active" : ""
        } wrapper_open_menu`}
        style={{ "--background": theme === "gray" ? "#F2F5F6" : "#003e47" }}
      >
        <div
          className={"menu_wrapper"}
          style={{ "--color": theme === "gray" ? "#003e47" : "#F2F5F6" }}
        >
          <span>{t("menu")}</span>
        </div>
        <div className={"navigation"}>
          <Link to={"/revision"} onClick={() => setShowMenu(false)} className='nav-bar'>
            <svg
              width='27'
              height='1'
              viewBox='0 0 27 1'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='27'
                height='1'
                fill={theme === "gray" ? "#003e47" : "#e5e5e5"}
                fillOpacity='0.2'
              />
            </svg>
            {t("audit")}
          </Link>
          <Link to={"/team"} onClick={() => setShowMenu(false)} className='nav-bar'>
            <svg
              width='27'
              height='1'
              viewBox='0 0 27 1'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='27'
                height='1'
                fill={theme === "gray" ? "#003e47" : "#e5e5e5"}
                fillOpacity='0.2'
              />
            </svg>
            {t("about")}
          </Link>
          <Link to={"/partner"} onClick={() => setShowMenu(false)} className='nav-bar'>
            <svg
              width='27'
              height='1'
              viewBox='0 0 27 1'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='27'
                height='1'
                fill={theme === "gray" ? "#003e47" : "#e5e5e5"}
                fillOpacity='0.2'
              />
            </svg>
            {t("partner")}
          </Link>
          <Link to={"/faq"} onClick={() => setShowMenu(false)} className='nav-bar'>
            <svg
              width='27'
              height='1'
              viewBox='0 0 27 1'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='27'
                height='1'
                fill={theme === "gray" ? "#003e47" : "#e5e5e5"}
                fillOpacity='0.2'
              />
            </svg>
            {t("FAQ")}
          </Link>
          <Link to={"/kontakt"} onClick={() => setShowMenu(false)} className={"nav-bar"}>
            <svg
              width='27'
              height='1'
              viewBox='0 0 27 1'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='27'
                height='1'
                fill={theme === "gray" ? "#003e47" : "#e5e5e5"}
                fillOpacity='0.2'
              />
            </svg>
            {t("contact")}
          </Link>
        </div>
        <div className='revision-and-login'>
          <Link
            to={`/kontakt/besprechung`}
            onClick={() => setShowMenu(false)}
            className={"btn_orange btn_mobile"}
          >
            {t("request_audit")}
          </Link>
          <Button
            text={t("log_in")}
            onClick={() => history.push("/login")}
            className={theme === "gray" ? "btn_blue" : ""}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
