import { Card } from "antd";
import clsx from "clsx";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/ui-components";

import classes from "./CardActions.module.scss";

const CardActions = ({
  className = "",
  cancelButtonColor = "red",
  cancelButtonProps = {},
  cancelText = i18next.t("dashboard:global:cancel"),
  primaryButtonType = "button",
  submitButtonColor = "green",
  submitDisabled,
  submitText,
  onCancel,
  onSubmit,
}) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <Card.Grid className={clsx(classes.root, className)} hoverable={false}>
      <Button color={cancelButtonColor} handleClick={onCancel} secondary {...cancelButtonProps}>
        {cancelText}
      </Button>
      <Button
        primary
        handleClick={onSubmit}
        disabled={submitDisabled}
        type={primaryButtonType}
        color={submitButtonColor}
      >
        {submitText || tGlobal("update")}
      </Button>
    </Card.Grid>
  );
};

export default CardActions;
