import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateAudit } from "../../../api/auditApi";
import { getRequests } from "../../../api/requestsPage";
import { getAllMeetings } from "../../../api/meetingsAPI";
import { getDocumentBacklinks } from "../../../api/documentApi";
import { getPresetPreview, getStatusAudit } from "../../../api/statusApi";
import { REQUEST_STATUSES } from "../../../consts/enums";
import { useFetch } from "../../../hooks";
import { DualScrollWrapper } from "../../../Admin/pages/components";
import { Loading } from "../../../components/ui-components";
import StatusList from "./components/StatusList";
import StatusView from "./components/StatusView";
import { useQuery } from "@tanstack/react-query";
import styles from "./Status.module.scss";

const initStatusFilters = Object.values(REQUEST_STATUSES);

const sortGroups = (groups) => (groups || []).slice().sort((a, b) => a.order - b.order);
const findDefaultGroup = (groups) => {
  const prioritized = groups.find((g) =>
    g.steps.some((step) => step.status === "in_progress" || step.status === "done")
  );
  return prioritized || groups[0];
};

const StatusPage = () => {
  const { id: idAudit } = useParams();
  const user = useSelector((state) => state.user);
  const userData = user?.user_data;
  const userRole = user?.user_role;

  const {
    isLoading: isStatusLoading,
    data,
    stealthRefetchAsync,
    setData,
  } = useFetch(getStatusAudit, idAudit);
  const { data: requestData, isLoading: isRequestLoading } = useFetch(
    getRequests,
    idAudit,
    initStatusFilters
  );
  const { data: meetingData, isLoading: isMeetingLoading } = useFetch(getAllMeetings, idAudit);
  const { data: documents, isLoading: isDocumentLoading } = useQuery({
    staleTime: Infinity,
    queryKey: ["documents", idAudit],
    queryFn: () => getDocumentBacklinks(idAudit),
  });

  const [selectedStatusGroup, setSelectedStatusGroup] = useState(null);
  const [updatedFields, setUpdatedFields] = useState({});
  const [updatedSteps, setUpdatedSteps] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [preview, setPreview] = useState(null);
  const [filter, setFilter] = useState([]);
  const { hasAdminPermissions, isAuditor } = userRole || {};
  const statusGroupStorageKey = `selectedStatusGroup_${idAudit}`;

  useEffect(() => {
    if (!data) return;

    const currentGroups = preview?.groups
      ? sortGroups(preview.groups)
      : sortGroups(data.audit.statusGroups);
    const storedGroupId = +sessionStorage.getItem(statusGroupStorageKey);
    const isStoredGroupValid = storedGroupId && currentGroups.some((g) => g.id === storedGroupId);

    if (isStoredGroupValid) {
      if (selectedStatusGroup !== storedGroupId) {
        setSelectedStatusGroup(storedGroupId);
      }
    } else {
      const defaultGroup = findDefaultGroup(currentGroups);
      if (!selectedStatusGroup || !currentGroups.find((g) => g.id === selectedStatusGroup)) {
        setSelectedStatusGroup(defaultGroup?.id);
      }
    }
  }, [preview, data, selectedStatusGroup, statusGroupStorageKey]);

  const [audit, selectedGroupData, hasAnyStatusProgress] = useMemo(() => {
    if (!data) return [null, null, false];

    const baseAudit = { ...data.audit, ...updatedFields };
    const groups = preview ? sortGroups(preview.groups) : sortGroups(baseAudit.statusGroups);

    const currentAudit = preview
      ? { ...data.audit, ...updatedFields, statusGroups: groups }
      : { ...baseAudit, statusGroups: groups };

    const currentGroup = groups.find((g) => g.id === selectedStatusGroup) || null;
    const anyProgress = groups.some((g) =>
      g.steps.some((step) => step.status !== "not_started" && step.status !== "in_progress")
    );

    return [currentAudit, currentGroup, anyProgress];
  }, [data, updatedFields, selectedStatusGroup, preview]);

  const syncData = async (partialData) => {
    setUpdatedFields((state) => ({ ...state, ...partialData }));
    await updateAudit(idAudit, partialData);
  };

  useEffect(() => {
    if (audit && idAudit && audit?.id !== +idAudit) {
      window.location.reload();
    }
  }, [idAudit, audit]);

  const previewPreset = async (name) => {
    const previewData = await getPresetPreview(idAudit, name);
    const sortedGroups = sortGroups(previewData.preset.groups);
    setPreview({
      ...previewData,
      groups: sortedGroups,
    });
  };

  const isUnsynced = useMemo(() => !audit?.isAuditUpToDate || !audit?.isTemplateUpToDate, [audit]);
  const isLoading =
    !userRole ||
    !audit ||
    isStatusLoading ||
    isRequestLoading ||
    isMeetingLoading ||
    isDocumentLoading;
  const isSuperadmin = userData?.role === "super-admin";
  const auditKind = data?.audit?.kind || "auditor";

  const activeGroupIdx = useMemo(() => {
    return audit?.statusGroups?.findIndex((i) => i.id === selectedStatusGroup);
  }, [selectedStatusGroup, audit?.statusGroups]);

  return (
    <div className={styles.wrapper}>
      {!isLoading ? (
        <DualScrollWrapper
          leftSidebar={
            <StatusList
              activeGroup={selectedStatusGroup}
              setActiveGroup={setSelectedStatusGroup}
              syncData={syncData}
              editMode={editMode}
              setEditMode={setEditMode}
              data={audit}
              setData={setData}
              companyPresets={data?.presets || []}
              setUpdatedSteps={setUpdatedSteps}
              previewPreset={previewPreset}
              setPreview={setPreview}
              isUnsynced={isUnsynced}
              hasAdminPermissions={hasAdminPermissions}
              isAuditor={isAuditor}
              filter={filter}
              setFilter={setFilter}
              hasAnyStatusProgress={hasAnyStatusProgress}
              isSuperadmin={isSuperadmin}
              auditKind={auditKind}
              statusGroupStorageKey={statusGroupStorageKey}
            />
          }
        >
          {selectedStatusGroup && (
            <StatusView
              data={selectedGroupData}
              setData={setData}
              selectedStatusGroup={selectedStatusGroup}
              editMode={editMode && !preview}
              updatedSteps={updatedSteps}
              idAudit={idAudit}
              hasAdminPermissions={hasAdminPermissions}
              stealthRefetchAsync={stealthRefetchAsync}
              requests={requestData?.requests || []}
              meetings={meetingData?.meetings || []}
              documents={documents || []}
              isUnsynced={isUnsynced}
              ownershipFilter={filter}
              auditKind={auditKind}
              activeGroupIdx={activeGroupIdx}
            />
          )}
        </DualScrollWrapper>
      ) : (
        <Loading position='absoluteCenter' />
      )}
    </div>
  );
};

export default StatusPage;
