import i18next from "i18next";
import { useState } from "react";
import TableHeader from "../../../../../../../Admin/components/TableHeader";
import DetailsContentHeader from "../DetailsContentHeader";
import StyledCollapse from "../StyledCollapse";
import AccountsTable from "./AccountsTable";
import StatusAccountsItem from "./StatusAccountsItem";

const SORT_TITLES = [
  { title: i18next.t("dashboard:global:account_name"), key: "account_name" },
  { title: i18next.t("dashboard:global:current_year"), key: "current_year" },
  { title: i18next.t("dashboard:global:prior_year"), key: "prior_year" },
];

export default function Accounts() {
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const items = [
    {
      key: "1",
      label: <StatusAccountsItem />,
      children: <AccountsTable />,
    },
    {
      key: "2",
      label: <StatusAccountsItem />,
      children: <AccountsTable />,
    },
  ];

  return (
    <>
      <DetailsContentHeader title='Accounts' onAdd={() => setIsOpenCreate(true)} />
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "1fr 100px 130px" }}
      />
      <StyledCollapse items={items} />
    </>
  );
}
