import React, { useEffect, useState } from "react";
import Button from "../customComponents/Button/Button";
import "./styles/revision.css";
import undraw_dev_productivity_umsq_1 from "../assets/images/undraw_dev_productivity_umsq_1.png";
import undraw_file_analysis_8k9b1 from "../assets/images/undraw_file_analysis_8k9b1.svg";
import undraw_order_confirmed_aaw71 from "../assets/images/undraw_order_confirmed_aaw71.svg";
import undraw_predictive_analytics_kf9n1 from "../assets/images/undraw_predictive_analytics_kf9n1.svg";
import undraw_Security_on_ff2u1 from "../assets/images/undraw_Security_on_ff2u1.svg";
import undraw_text_files_au1q1 from "../assets/images/undraw_text_files_au1q1.svg";
import undraw_unlock_24mb1 from "../assets/images/undraw_unlock_24mb1.svg";
import revision from "../assets/images/undraw_File_bundle_xl7g 1.svg";
// import Google from '../assets/images/google.jpeg';
import abacus_svg1 from "../assets/images/abacus-svg1.svg";
import bexio_logo_svg1 from "../assets/images/logos/bexio-logo-svg1.svg";
import myfactory_Logo_5_0_svg1 from "../assets/images/myfactory-Logo-5_0-svg1.svg";
import sage_logo_svg1 from "../assets/images/sage-logo-svg1.svg";
import Slider from "react-slick";
import undraw from "../assets/images/undraw.svg";
import BlueWindow from "../customComponents/BlueWindow/BlueWindow";
import { otherConst } from "../consts/otherConst";
import Popup from "../components/Popup";
import useResize from "../hooks/useResize";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const everyUniqueAIVO = [
  {
    title: "unique_title_1",
    img: undraw_text_files_au1q1,
    description: "unique_description_1",
  },
  {
    title: "unique_title_2",
    img: undraw_Security_on_ff2u1,
    description: "unique_description_2",
  },
  {
    title: "unique_title_3",
    img: undraw_unlock_24mb1,
    description: "unique_description_3",
  },
  {
    title: "unique_title_4",
    img: undraw_file_analysis_8k9b1,
    description: "unique_description_4",
  },
  {
    title: "unique_title_5",
    img: undraw_order_confirmed_aaw71,
    description: "unique_description_5",
  },
  {
    title: "unique_title_6",
    img: undraw_predictive_analytics_kf9n1,
    description: "unique_description_6",
  },
];

const PriceArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <div
    {...props}
    className={`art_price_body_slider_arrow art_price_body_slider_arrow_left ${props.className}`}
  >
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_331_2747)'>
        <path
          d='M17.5754 7.93836L11.6494 2.0124C11.3801 1.72905 11.0075 1.56661 10.6165 1.561C10.2256 1.55608 9.84944 1.70936 9.5724 1.98569C9.29606 2.26203 9.14279 2.63819 9.14701 3.02913C9.15193 3.42078 9.31435 3.79273 9.59771 4.06273L13.1007 7.56013H1.45055C0.649692 7.56013 0 8.20982 0 9.01067C0 9.81223 0.649692 10.4619 1.45055 10.4619H13.0683L9.59771 13.9375C9.0556 14.5085 9.06685 15.4071 9.62372 15.9633C10.1806 16.5194 11.0799 16.5307 11.6494 15.9879L17.5754 10.0619C17.8482 9.79051 18.0015 9.42137 18.0001 9.03606C18.0008 9.0241 18.0008 9.01215 18.0001 9.0002C18.0008 8.98824 18.0008 8.97629 18.0001 8.96434C18.0001 8.57973 17.8475 8.21059 17.5754 7.93848L17.5754 7.93836Z'
          fill='#003E47'
        />
      </g>
      <defs>
        <clipPath id='clip0_331_2747'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);
const PriceArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <div
    {...props}
    className={`art_price_body_slider_arrow art_price_body_slider_arrow_right ${props.className}`}
  >
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_331_2747)'>
        <path
          d='M17.5754 7.93836L11.6494 2.0124C11.3801 1.72905 11.0075 1.56661 10.6165 1.561C10.2256 1.55608 9.84944 1.70936 9.5724 1.98569C9.29606 2.26203 9.14279 2.63819 9.14701 3.02913C9.15193 3.42078 9.31435 3.79273 9.59771 4.06273L13.1007 7.56013H1.45055C0.649692 7.56013 0 8.20982 0 9.01067C0 9.81223 0.649692 10.4619 1.45055 10.4619H13.0683L9.59771 13.9375C9.0556 14.5085 9.06685 15.4071 9.62372 15.9633C10.1806 16.5194 11.0799 16.5307 11.6494 15.9879L17.5754 10.0619C17.8482 9.79051 18.0015 9.42137 18.0001 9.03606C18.0008 9.0241 18.0008 9.01215 18.0001 9.0002C18.0008 8.98824 18.0008 8.97629 18.0001 8.96434C18.0001 8.57973 17.8475 8.21059 17.5754 7.93848L17.5754 7.93836Z'
          fill='#003E47'
        />
      </g>
      <defs>
        <clipPath id='clip0_331_2747'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const Revision = () => {
  const [activeWidthBetweenSlide, setActiveWidthBetweenSlide] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const { innerWidth } = useResize();
  const { t } = useTranslation(["revision", "homepage"]);

  //eslint-disable-next-line
  const growingProfileSettings = {
    infinite: true,
    slidesToShow: innerWidth > 768 ? 4 : innerWidth < 400 ? 2 : 3,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  const revisionSettings = {
    infinite: false,
    slidesToShow: innerWidth > 768 ? 2 : 1,
    slidesToScroll: 1,
    prevArrow: <PriceArrowLeft />,
    nextArrow: <PriceArrowRight />,
  };

  useEffect(() => {
    let element = document.querySelector(".wrapper_app");
    element.style.overflow = "hidden";
    return () => {
      element.style.overflow = "unset";
    };
  }, []);

  const getWidthBetween = () => {
    let elem = document.querySelectorAll(".editorial_wrapper__every");

    if (!elem || !elem[0] || !elem[0].clientWidth) {
      return;
    }
    setActiveWidthBetweenSlide(elem[0].clientWidth);
  };

  useEffect(() => {
    getWidthBetween();

    window.addEventListener("resize", () => {
      getWidthBetween();
    });

    return () => {
      window.removeEventListener("resize", () => {
        getWidthBetween();
      });
    };
  }, []);

  const [modalActive, setModalActive] = useState(false);

  return (
    <div className='revision_wrapper'>
      <Helmet>
        <title>Revision | aivo</title>
        <meta name='theme-color' content='#f2f5f6' />
        <style>{"body { background-color: #f2f5f6; }"}</style>
      </Helmet>
      <section className={"revision_wrapper__view_without_documents"}>
        <div className='info'>
          <span className={"title"}>{t("title")}</span>
          <p className={"description"}>{t("description")}</p>
          <Link to={`/kontakt/besprechung`} className={"btn_orange btn_default"}>
            {t("revision_btn")}
          </Link>
        </div>
        <img src={revision} className={"revision-img"} alt='revision' />
      </section>
      <section className={"revision_wrapper__waste_time"}>
        <div className={"image_wrapper"}>
          <img src={undraw_dev_productivity_umsq_1} alt='undraw_dev_production' />
        </div>
        <div className={"info"}>
          <div className={"title"}>
            <span>{t("repetitive_tasks_title")}</span>
          </div>
          <div className={"description"}>
            <p>{t("repetitive_tasks_description_1")}</p>
            <p>{t("repetitive_tasks_description_2")}</p>
          </div>
        </div>
      </section>
      <section className={"editorial_wrapper"}>
        <div className={"editorial_wrapper_title"}>
          <span>{t("audit_steps_title", { ns: "homepage" })}</span>
        </div>
        <Slider {...revisionSettings} afterChange={(e) => setActiveSlide(e)}>
          {otherConst.revision_5_steps.map((i, index) => (
            <div
              className={`editorial_wrapper__every ${
                index === otherConst.revision_5_steps.length - 1
                  ? "editorial_wrapper__every_last"
                  : ""
              }`}
              key={index}
            >
              <div
                className={"img_wrapper"}
                style={{
                  "--widthBetweenSlide": `${activeWidthBetweenSlide}px`,
                }}
              >
                <img src={i.img} alt={"logo"} />
              </div>
              {!index || index === otherConst.revision_5_steps.length - 1 ? (
                <svg
                  className='line'
                  height='48'
                  viewBox='0 0 573 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M280 23.5531C378 75.224 484.004 23.5529 572.459 23.553'
                    stroke='#F17F16'
                  />
                </svg>
              ) : (
                <svg
                  className='line'
                  height='48'
                  viewBox='0 0 573 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0.00437944 23.5531C32.4995 23.5531 182 -28.1179 280 23.5531C378 75.224 484.004 23.5529 572.459 23.553'
                    stroke='#F17F16'
                  />
                </svg>
              )}

              <p className={"title"}>{t(i.title, { ns: "homepage" })}</p>
              <p className={"description"}>{t(i.description, { ns: "homepage" })}</p>
              {activeSlide === index ? (
                <Link
                  to={"/kontakt/besprechung"}
                  className={"btn_orange btn_default revison_button"}
                >
                  {"Revision anfragen"}
                </Link>
              ) : null}
            </div>
          ))}
        </Slider>
      </section>
      <section className='aivo_unique'>
        <div className={"aivo_unique__title"}>
          <span className={"title"}>{t("unique_aivo")}</span>
        </div>
        <div className={"aivo_unique__wrapper"}>
          {everyUniqueAIVO.map((i, index) => (
            <div className='every' key={index}>
              <img src={i.img} alt={"logo"} />
              <p className={"title"}>{t(i.title)}</p>
              <p className={"description"}>{t(i.description)}</p>
            </div>
          ))}
        </div>
      </section>
      {/*<section className={'growing_profile_wrapper'}>*/}
      {/*  <div className={'wrapper'}>*/}
      {/*    <div className={'wrapper_title'}>*/}
      {/*      <span>Unser wachsendes Partner-Profil</span>*/}
      {/*    </div>*/}
      {/*    <Slider {...growingProfileSettings}>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*      <div className={'partners-logo'}>*/}
      {/*        <img src={Google} alt='Google' />*/}
      {/*      </div>*/}
      {/*    </Slider>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className='supported_accounting'>
        <div className={"info"}>
          <span className={"title"}>{t("accounting_tools_title")}</span>
          <p className='description'>{t("accounting_tools_description")}</p>
        </div>
        <div className={"preview"}>
          <div className={"every"}>
            <img src={abacus_svg1} alt='abacus_svg1' />
          </div>
          <div className={"every"}>
            <img src={bexio_logo_svg1} alt='bexio_logo_svg1' />
          </div>
          <div className={"every"}>
            <img src={myfactory_Logo_5_0_svg1} alt='myfactory_Logo_5_0_svg1' />
          </div>
          <div className={"every"}>
            <img src={sage_logo_svg1} alt='sage_logo_svg1' />
          </div>
          <Button
            onClick={() => setModalActive(true)}
            className={"btn_gray"}
            text={t("accounting_tools_button")}
          />
          <Popup active={modalActive} setActive={setModalActive} />
        </div>
      </section>

      <BlueWindow
        title={t("blue_window_btn_team", { ns: "homepage" })}
        linkTo={"/kontakt/besprechung"}
        desc={t("blue_window_desc_revision", { ns: "homepage" })}
        src={undraw}
        btnText={t("blue_window_btn_revision", { ns: "homepage" })}
      />
    </div>
  );
};

export default Revision;
