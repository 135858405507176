import React from "react";
import styles from "./DropzoneUpload.module.scss";
import { FolderIcon } from "../../../../../AdminComponents/icons";
import { useDropzone } from "react-dropzone";

const DropzoneUpload = ({ onDrop, className, multiple = false, children, inset }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple,
    onDropAccepted: onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={`${isDragActive ? "parent-upload-drag-active" : ""} ${className || ""}`}
    >
      <input type='file' name='attachments' hidden {...getInputProps()} />
      <div className={styles.attachmentDropzone} {...(inset && { style: { inset } })}>
        <div className={styles.visualAssist}>
          <FolderIcon />
          <p>Drop files here to upload them to the audit.</p>
        </div>
      </div>
      {children}
    </div>
  );
};

export default DropzoneUpload;
