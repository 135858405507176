import { Modal, ModalProps } from "antd";
import clsx from "clsx";

import styles from "./StyledModal.module.scss";

type StyledModalProps = {} & ModalProps;

export default function StyledModal({ children, className, ...props }: StyledModalProps) {
  return (
    <Modal centered closeIcon={null} {...props} className={clsx(styles.root, className)}>
      {children}
    </Modal>
  );
}
