import { Col, Divider, Input, Radio, Row } from "antd";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import i18next from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TableHeader from "../../../../../../../../Admin/components/TableHeader";
import { CommentsViewer } from "../../../../../../../../Admin/pages/components";
import AttachmentRow from "../../../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";
import PageTextEditor from "../../../../../../../../Admin/pages/components/PageTextEditor/PageTextEditor";
import { PenIcon } from "../../../../../../../../assets/icons";

import { ReactComponent as FlagIcon } from "../../../../../../../../assets/icons/flag.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/icons/plusBold.svg";
import { ReactComponent as PlusRoundedIcon } from "../../../../../../../../assets/icons/plus-rounded-filled.svg";
import CustomDrawer from "../../../../../../../../components/CustomDrawer";
import CustomSlider from "../../../../../../../../components/CustomSlider";
import StatusDropdown, {
  StatusDropdownStatus,
} from "../../../../../../../../components/StatusDropdown";
import { Button, Label, Tag } from "../../../../../../../../components/ui-components";
import { StatusStep } from "../../../../../../../../types/status";
import DocumentRowBacklinks from "../../../../../../Documents/components/DocumentRowBacklinks";
import RiskItem from "../RiskItem";
import styles from "./RiskResponseViewDrawer.module.scss";

type Props = {
  documents?: StatusStep["attachedDocuments"];
  open: boolean;
  onClose: () => void;
};

const statuses: Array<StatusDropdownStatus<string>> = [
  { key: "identified", color: "yellow" },
  { key: "in_progress", color: "blue" },
  { key: "done", color: "green" },
];

interface FormInputs {
  type: "fraud" | "significant" | "controls" | "other";
  description: string;
}

type EditorStates = "description";

export const AFFECTED_ACCOUNTS_TITLES = [
  { title: i18next.t("dashboard:status:affected_accounts"), key: "affected_accounts" },
  { title: i18next.t("dashboard:global:current_year"), key: "current_year" },
  { title: i18next.t("dashboard:global:prior_year"), key: "type" },
];

export const MITIGATING_CONTROLS_TITLES = [
  { title: i18next.t("dashboard:global:level"), key: "level" },
  { title: i18next.t("dashboard:global:description"), key: "description" },
  { title: i18next.t("dashboard:global:type"), key: "type" },
];

export default function RiskResponseViewDrawer({ documents, ...props }: Props) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [editorStates, setEditorStates] = useState<Record<EditorStates, EditorState>>({
    description: EditorState.createEmpty(),
  });

  const { watch, setValue, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      type: "fraud",
    },
  });

  const onEditorStateChange = (state: EditorState, name: EditorStates) => {
    setEditorStates((prev) => ({ ...prev, [name]: state }));
  };

  const onSubmit = (data: FormInputs) => {
    const htmlDescription = draftToHtml(convertToRaw(editorStates.description.getCurrentContent()));
    console.log(data, htmlDescription);
  };

  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <CustomDrawer
      {...props}
      title={t("risk_view")}
      rightHeaderContent={
        <Row gutter={16} justify='end'>
          <Col>
            <Button primary color='lightRed' onClick={props.onClose}>
              {tGlobal("delete")}
            </Button>
          </Col>
          <Col>
            {editMode ? (
              <Button primary color='blue' onClick={toggleEditMode}>
                {tGlobal("create")}
              </Button>
            ) : (
              <Button small primary color='blue' onClick={toggleEditMode}>
                <PenIcon /> {tGlobal("edit")}
              </Button>
            )}
          </Col>
        </Row>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {editMode ? (
          <>
            <Label gutterBottom>{t("risk_name")}</Label>
            <Row align='middle' gutter={16}>
              <Col span={19}>
                <Input />
              </Col>
              <Col span={5}>
                <StatusDropdown value='1' onChange={() => {}} statuses={statuses} />
              </Col>
            </Row>
            <div>
              <Button primary small color='gray' className={styles.addButton}>
                <PlusIcon width='1.2em' height='1.2em' /> {t("add_assertion")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Row align='middle' gutter={16}>
              <Col span={19}>
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <div className={styles.title}>
                      Existance & cut-off of subsidy related revenue
                    </div>
                  </Col>
                  <Col span={24}>
                    <Tag color='blue'>Existence / Occurrence</Tag>
                  </Col>
                  <Col span={24}>
                    <DocumentRowBacklinks total={10} requests={[]} />
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <StatusDropdown value='1' onChange={() => {}} statuses={statuses} />
              </Col>
            </Row>
          </>
        )}

        <Divider className={styles.divider} />
        <div className={styles.inner}>
          <Label gutterBottom>{t("risk_type_label")}</Label>
          <Radio.Group
            disabled={!editMode}
            value={watch("type")}
            onChange={(e) => setValue("type", e.target.value)}
          >
            <Radio.Button value='fraud'>{t("risk_type_fraud")}</Radio.Button>
            <Radio.Button value='significant'>{t("risk_type_significant")}</Radio.Button>
            <Radio.Button value='controls'>{t("risk_type_controls")}</Radio.Button>
            <Radio.Button value='other'>{t("risk_type_other")}</Radio.Button>
          </Radio.Group>
          <div className={styles.helperText}>{t("risk_type_description")}</div>
        </div>
        <Row>
          <Col xs={editMode ? 12 : 24}>
            <div className={styles.inner}>
              <Label gutterBottom>{t("risk_inherent_risk_label")}</Label>
              <CustomSlider viewMode={editMode} />
            </div>
          </Col>
          <Col xs={editMode ? 12 : 24}>
            <div className={styles.inner}>
              <Label gutterBottom>{t("risk_audit_risk_label")}</Label>
              <CustomSlider viewMode={editMode} />
            </div>
          </Col>
        </Row>
        <div className={styles.inner}>
          {editMode ? (
            <PageTextEditor
              value={watch("description")}
              editorState={editorStates.description}
              onEditorStateChange={onEditorStateChange}
              name='description'
              label={tGlobal("description")}
            />
          ) : (
            <>
              <Label gutterBottom>{tGlobal("description")}</Label>
              <div className={styles.description}>Description</div>
            </>
          )}
        </div>
        <div className={styles.inner}>
          <Label gutterBottom>{tGlobal("documents")}</Label>
          {documents?.map((document) => (
            <AttachmentRow deleteDocument file={document} />
          ))}
        </div>
        <div className={styles.inner}>
          <Label gutterBottom>{t("affected_accounts")}</Label>
          <TableHeader
            titles={AFFECTED_ACCOUNTS_TITLES}
            wrapperStyles={{ gridTemplateColumns: "1fr 120px 120px" }}
          />
          <div className={styles.affectedAccountsItem}>
            <div className={styles.itemInner}>
              <FlagIcon className={styles.flagIcon} />
              <span>1000</span>
              <span>Cash on Hand</span>
            </div>
            <div className={styles.itemInner}>
              <span>€</span>
              <span>10,000.00</span>
            </div>
            <div className={styles.itemInner}>
              <span>€</span>
              <span>10,000.00</span>
            </div>
          </div>
          {editMode && (
            <Button small primary color='lightBlue'>
              <PlusRoundedIcon width={16} height={16} />
              {tGlobal("add_account")}
            </Button>
          )}
        </div>
        <div className={styles.inner}>
          <Label gutterBottom>{t("mitigating_controls")}</Label>
          <TableHeader
            titles={MITIGATING_CONTROLS_TITLES}
            wrapperStyles={{ gridTemplateColumns: "80px 1fr auto" }}
          />
          <RiskItem type='Fraud' title='Sed posuere consectetur est a' status='inquiry' />
          {editMode && (
            <Button small primary color='lightBlue'>
              <PlusRoundedIcon width={16} height={16} />
              {tGlobal("add_control")}
            </Button>
          )}
        </div>
        <div className={styles.inner}>
          <Label gutterBottom>{t("risk_response")}</Label>
          <TableHeader
            titles={MITIGATING_CONTROLS_TITLES}
            wrapperStyles={{ gridTemplateColumns: "80px 1fr auto" }}
          />
          <RiskItem type='Fraud' title='Sed posuere consectetur est a' status='inquiry' />
          {editMode && (
            <Button small primary color='lightBlue'>
              <PlusRoundedIcon width={16} height={16} />
              {tGlobal("add_subtask")}
            </Button>
          )}
        </div>
        <Divider className={styles.divider} />
      </form>
    </CustomDrawer>
  );
}
