const defaultAuditorRoles = [
  {
    label: "Auditor",
    value: "auditor",
  },
  {
    label: "Quality assurance",
    value: "quality_assurance",
  },
  {
    label: "Quality review",
    value: "quality_review",
  },
];

const defaultFiduciaryRoles = [
  {
    label: "Lead accountant",
    value: "lead_accountant",
  },
  {
    label: "Accountant",
    value: "accountant",
  },
];

export const getAuditorRoleOptions = (auditors, kind) => {
  const hasLeadAuditor = auditors.some((i) => i.role === "lead_auditor");
  const roleOptions =
    kind === "fiduciary"
      ? defaultFiduciaryRoles
      : [
          {
            label: "Lead auditor",
            value: "lead_auditor",
            disabled: hasLeadAuditor,
          },
          ...defaultAuditorRoles,
        ];
  const roleWithActions = [
    ...roleOptions,
    { label: "Remove user", value: "remove", caution: true },
  ];
  const initInvite = {
    email: "",
    role: roleOptions[1]?.value,
  };

  return [roleOptions, roleWithActions, initInvite];
};
