import { Col, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "../../components/ui-components";
import StyledModal from "../../components/ui-components/StyledModal";
import CardActions from "../pages/components/CardActions";

const DeleteAuditPopup = ({ onClose, isOpen, companyName, onSubmit }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [deleteValue, setDeleteValue] = useState("");

  const closeDeleteCompanyPopup = () => {
    setDeleteValue("");
    onClose();
  };

  return (
    <StyledModal
      width={700}
      className='delete-audit-popup'
      title={t("delete_audit")}
      footer={
        <CardActions
          onCancel={closeDeleteCompanyPopup}
          onSubmit={onSubmit}
          submitText={t("delete")}
          submitDisabled={deleteValue !== companyName}
          submitButtonColor={"red"}
          cancelButtonColor={"blue"}
          className='card-actions'
        />
      }
      onClose={closeDeleteCompanyPopup}
      open={isOpen}
    >
      <Space.Compact direction={"vertical"} block className={"popup-body"}>
        <Row gutter={24} span={24}>
          <Col span={8}>
            <span>Confirm deletion</span>
            <span className='caption'>{t("audit_deletion_description")}</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder={t("audit_name")}
              type='name'
              value={deleteValue}
              onChange={({ target }) => {
                setDeleteValue(target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
    </StyledModal>
  );
};

export default DeleteAuditPopup;
