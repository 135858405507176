import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { superadminCreateCompany } from "../api/superadminApi";
import CompanyNameAutocomplete from "./CompanyNameAutocomplete";

import FileUpload from "./FileUpload";
import CardActions from "../Admin/pages/components/CardActions/CardActions";
import Popup from "./ui-components/Popup/Popup";
import FormPicker from "../Admin/pages/components/FormPicker/FormPicker";
import CompanyIDInput from "./CompanyIDInput";

const initAuditData = {
  logo: "",
  logoURL: "",
  companyName: "",
  companyId: "",
  companyType: "client",
};

const SuperadminAddCompany = ({ close, isOpen, onSuccess }) => {
  const [error, setError] = useState(false);
  const [activeData, setActiveData] = useState(false);
  const [auditData, setAuditData] = useState(initAuditData);
  const logo = auditData.logoURL;

  const onSubmit = async () => {
    const { companyId, companyName, companyType } = auditData;
    if (!(companyId && companyName)) {
      setError(true);
      return;
    }
    try {
      const res = await superadminCreateCompany({
        logo: auditData.logo,
        UID: companyId,
        name: companyName,
        type: companyType,
      });
      if (res.id) {
        onSuccess();
        setAuditData(initAuditData);
      } else {
        setError(res.response?.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    setActiveData(auditData.companyName.length > 0 && auditData.companyId.length > 0);
  }, [auditData]);

  const inputData = (key, value) => {
    setAuditData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setError(false);
  };

  const onCompanySelect = (company) => {
    setAuditData((prevState) => ({
      ...prevState,
      companyName: company.name,
      companyId: company.uid,
    }));
  };

  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const companyTypes = [
    { value: "client", name: tGlobal("client") },
    { value: "fiduciary", name: tGlobal("partner") },
    { value: "audit_firm", name: tGlobal("auditor") },
  ];

  return (
    <Popup isOpen={isOpen} title={tGlobal("create_company")} onClose={close}>
      <Space.Compact direction={"vertical"} block className={"popup-body"}>
        <Row gutter={24}>
          <Col span={8}>
            <span>{tGlobal("company_name")}</span>
          </Col>
          <Col span={16}>
            <CompanyNameAutocomplete
              error={error === "Company already exists"}
              value={auditData.companyName}
              onChange={inputData}
              onSelect={onCompanySelect}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span className='company_field'>{tGlobal("company_id")}</span>
          </Col>
          <Col span={16}>
            <CompanyIDInput
              error={error && !auditData.companyId}
              value={auditData.companyId}
              onChange={inputData}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span className='company_field'>{tGlobal("company_type")}</span>
          </Col>
          <Col span={16}>
            <FormPicker
              options={companyTypes}
              selected={auditData.companyType}
              error={error && !auditData.companyType}
              name='companyType'
              onSelect={inputData}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span>{tGlobal("company_logo")}</span>
          </Col>
          <Col span={16}>
            <FileUpload
              title={tGlobal("select_picture")}
              file={logo}
              subtitle={"JPG, PNG"}
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              onChange={(file) => {
                inputData("logo", file);
                inputData("logoURL", file ? URL.createObjectURL(file) : "");
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
      <CardActions
        onCancel={() => close(false)}
        onSubmit={onSubmit}
        submitText={tGlobal("create")}
        submitDisabled={!activeData}
      />
    </Popup>
  );
};

export default SuperadminAddCompany;
