import { Tag } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Line } from "rc-progress";
import moment from "moment";

import toFullName from "../../../../consts/toFullName";
import {
  FinishedStatusArrow,
  InProgressStatusArrow,
  NotStartedStatusArrow,
} from "../../../../assets/icons";

interface Step {
  status: "done" | "in_progress" | "not_started";
}

interface StatusGroup {
  id: string;
  order: number;
  name: string;
  steps: Step[];
}

interface Auditor {
  firstName: string;
  lastName: string;
}

interface Firm {
  name: string;
}

interface Audit {
  name: string;
  reportDate?: string;
  statusGroups: StatusGroup[];
}

interface StatusSectionProps {
  audit?: Audit;
  selectedStatusGroup: string;
  statusGroups: StatusGroup[];
  auditor?: Auditor;
  auditorFirm?: Firm;
}

const StatusSection: React.FC<StatusSectionProps> = ({
  audit,
  selectedStatusGroup,
  statusGroups,
  auditor,
  auditorFirm,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { t: tStatus } = useTranslation("dashboard", { keyPrefix: "status" });

  const [selectedGroupNumber, selectedGroupData] = useMemo(() => {
    if (!audit) return [null, null];
    const sortedGroups = audit.statusGroups.sort((a, b) => a.order - b.order);
    const idx = sortedGroups.findIndex((group) => group.id === selectedStatusGroup);
    return [idx + 1, sortedGroups[idx]];
  }, [audit, selectedStatusGroup]);

  const allSteps = useMemo(() => statusGroups.flatMap((group) => group.steps), [statusGroups]);
  const allStepAmount = allSteps.length;
  const doneStepAmount = allSteps.filter((step) => step.status === "done").length;
  const progress = allStepAmount ? (doneStepAmount / allStepAmount) * 100 : 0;

  const progressGridStyles = useMemo(() => {
    const finishedStepCount = selectedGroupNumber ? selectedGroupNumber - 1 : 0;
    return {
      gridTemplateColumns: `${
        finishedStepCount ? `repeat(${finishedStepCount}, minmax(115px, 10%))` : ""
      } repeat(${statusGroups.length - finishedStepCount}, minmax(115px, 1fr))`,
    };
  }, [selectedGroupNumber, statusGroups.length]);

  if (!audit || !selectedGroupData) return null;

  return (
    <div className='body'>
      <div className='body_inner'>
        <div className='date'>
          <span className='title'>{audit.name}</span>
          <div className='title_sub'>
            {auditorFirm && (
              <p className='description'>
                {t("audit_company")}: {auditorFirm.name}
              </p>
            )}
            {auditor && (
              <p className='description'>
                {t("role_lead_auditor")}: {toFullName(auditor)}
              </p>
            )}
          </div>
        </div>
        <div className='progress_line'>
          <span className='title'>{t("progress")}</span>
          <p className='percent'>{progress.toFixed(0)}%</p>
          <Line
            percent={progress}
            className='line_progress'
            strokeWidth={4}
            trailColor='rgba(0, 62, 71, 0.1)'
            trailWidth={4}
            strokeColor='#6DB333'
          />
        </div>
        <div className='time'>
          <span className='title'>{t("audit_report_date")}</span>
          {audit.reportDate && (
            <div className='title_sub'>
              <p className='description'>{moment(audit.reportDate).format("DD.MM.YYYY")}</p>
              <p className='description'>{moment(audit.reportDate).fromNow()}</p>
            </div>
          )}
        </div>
      </div>

      <div className='progress_bar'>
        <div className='progress_line' style={progressGridStyles}>
          {statusGroups
            .sort((a, b) => a.order - b.order)
            .map((group, idx) => {
              const isDone = group.steps.every((step) => step.status === "done");
              const isInProgress = group.steps.some((step) => step.status === "in_progress");
              const zIdx = statusGroups.length - idx;

              const IconComponent = isDone
                ? FinishedStatusArrow
                : isInProgress
                ? InProgressStatusArrow
                : NotStartedStatusArrow;

              return (
                <div key={group.id} style={{ zIndex: zIdx }} className='status-progress-arrow'>
                  <IconComponent />
                  <Tag>
                    {idx + 1}. {group.name}
                  </Tag>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StatusSection;
