import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Document } from "../../types/document";
import {
  addDocument,
  addMultipleDocuments,
  deleteDocument,
  editDocuments,
  replaceDocument,
} from "../../api/documentApi";

export const useDocuments = () => {
  const queryClient = useQueryClient();

  const createDocumentMutation = useMutation({
    mutationFn: addDocument,
    onSuccess: (result, variables, context) => {
      console.log({ result, variables, context });
      queryClient.setQueryData(["documents"], (old: Document[]) => {
        // TODO: this is a temporary solution (old.some()) because the socket notification
        //  comes before the response from the server, so onSuccess here is called after
        //  refetching the list of documents, which causes duplicates
        return old.some((i) => i.id === result.document.id) ? old : [result.document, ...old];
      });
    },
  });

  const createMultipleDocumentsMutation = useMutation({
    mutationFn: addMultipleDocuments,
    onSuccess: (result) => {
      queryClient.setQueryData(["documents"], (old: Document[]) => {
        // temporary solution (old.some()) because the socket notification
        const newIds = result.documents.map((doc: Document) => doc.id);
        return old.some((i) => newIds.includes(i.id)) ? old : [...result.documents, ...old];
      });
    },
  });

  const updateDocumentMutation = useMutation({
    mutationFn: editDocuments,
    onSuccess: (result, variables) => {
      const newData = {
        name: result.name,
        requiresSignature: result.signers?.length > 0,
        signers: result.signers,
        type: result.type,
      };
      queryClient.setQueryData(["documents"], (old: Document[]) =>
        old.map((document) =>
          variables.documentId === document.id ? { ...document, ...newData } : document
        )
      );
    },
  });

  const replaceDocumentMutation = useMutation({
    mutationFn: replaceDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documents"] });
    },
  });

  const updateDocumentNameMutation = useMutation({
    mutationFn: editDocuments,
    onSuccess: (result, variables) => {
      const newData = {
        name: result.name,
      };
      queryClient.setQueryData(["documents"], (old: Document[]) =>
        old.map((document) =>
          variables.documentId === document.id ? { ...document, ...newData } : document
        )
      );
    },
  });

  const deleteDocumentMutation = useMutation({
    mutationFn: deleteDocument,
    onMutate: (variables) => {
      queryClient.setQueryData(["documents"], (old: Document[]) =>
        old.filter((document) => document.id !== variables.documentId)
      );
      queryClient.invalidateQueries({ queryKey: ["documents", "deleted"] });
    },
  });

  return {
    createDocument: createDocumentMutation.mutate,
    createMultipleDocuments: createMultipleDocumentsMutation.mutate,
    deleteDocument: deleteDocumentMutation.mutate,
    replaceDocument: replaceDocumentMutation.mutate,
    updateDocument: updateDocumentMutation.mutate,
    updateDocumentName: updateDocumentNameMutation.mutate,
    // queryClient,
  };
};
