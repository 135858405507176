import { useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { set } from "lodash";

import { i18nResources } from "../../../../i18n";
import { superadminDownloadTranslations } from "../../../../api/superadminApi";

import Tabs from "../../../../components/ui-components/Tabs/Tabs";
import TableHeader from "../../../components/TableHeader";
import PlatformAdminTitleBlock from "../../components/PlatformAdminTitleBlock";
import ManageTranslationsRow from "./components/ManageTranslationsRow";
import { Button } from "../../../../components/ui-components";

import {
  MANAGE_TRANSLATIONS_TABS,
  SORT_TITLES,
  STORAGE_KEYS,
} from "./ManageTranslations.constants";

import styles from "./ManageTranslations.module.scss";
import { isEdited } from "./components/ManageTranslationsRow/ManageTranslationsRow";

type EditedFields = {
  [key: string]: isEdited;
};

export default function ManageTranslations() {
  const storedData = localStorage.getItem(STORAGE_KEYS.translations);
  const initData = storedData ? JSON.parse(storedData) : i18nResources;

  const [tab, setTab] = useState(MANAGE_TRANSLATIONS_TABS[0]);
  const [data, setData] = useState<any>(initData);
  const listPerPage = data.en.dashboard[tab];

  const handleExport = async () => {
    await superadminDownloadTranslations(data);
  };

  const handleChange = (key: string) => (e: any) => {
    const { name: lang, value } = e.target;
    const newData = cloneDeep(data);
    set(newData, `${lang}.dashboard.${tab}.${key}`, value);
    localStorage.setItem(STORAGE_KEYS.translations, JSON.stringify(newData));
    setData(newData);
  };

  const handleReset = () => {
    localStorage.removeItem(STORAGE_KEYS.translations);
    setData(i18nResources);
  };

  const [editedFields, hasChanges] = useMemo(() => {
    const edited: EditedFields = {};

    const enTranslations = i18nResources.en.dashboard[tab];
    const deTranslations = i18nResources.de.dashboard[tab];
    (Object.keys(enTranslations) as Array<keyof typeof enTranslations>).forEach((key) => {
      const enEdited = enTranslations[key] !== data.en.dashboard[tab][key];
      const deEdited = deTranslations[key] !== data.de.dashboard[tab][key];
      if (enEdited || deEdited) {
        edited[key] = {
          en: enEdited,
          de: deEdited,
        };
      }
    });
    const hasChanges = Object.keys(edited).length > 0;
    return [edited, hasChanges];
  }, [data]);

  return (
    <>
      <PlatformAdminTitleBlock onExport={handleExport} />
      <div className={styles.actions}>
        <Tabs value={tab} onClick={setTab} options={MANAGE_TRANSLATIONS_TABS} t={undefined} />
        {hasChanges && (
          <div>
            <Button color='blue' secondary handleClick={handleReset}>
              Refetch
            </Button>
          </div>
        )}
      </div>
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "250px minmax(200px, 1fr) minmax(200px, 1fr)" }}
      />
      {Object.keys(listPerPage).map((key) => (
        <ManageTranslationsRow
          key={key}
          i18key={key}
          english={data.en.dashboard[tab][key]}
          german={data.de.dashboard[tab][key]}
          isEdited={editedFields[key]}
          onChange={handleChange(key)}
        />
      ))}
    </>
  );
}
