import classNames from "classnames";
import { ChangeEvent, MouseEvent, SyntheticEvent, useMemo, useState } from "react";
import {
  AutocompleteCloseReason,
  Button,
  ClickAwayListener,
  Popper,
  InputAdornment,
  TextField,
} from "@mui/material";

import COUNTRIES, { Country } from "../../../consts/countries";

import StyledAutocomplete from "../StyledAutocomplete";

import { CheckmarkIcon, SearchIcon, ThinChevronBottom } from "../../../assets/icons";

import styles from "./SelectCountryAutocomplete.module.scss";

type SelectCountryAutocompleteProps = {
  isHighlighted?: boolean;
  value?: string;
  onChange?: (val: string) => void;
};

function sortCountries(countries: Array<Country>, selectedCountryCode: Country["code"]) {
  // Sort the countries alphabetically by label
  const sortedCountries = countries.sort((a, b) => a.label.localeCompare(b.label));

  // Find the selected country
  const selectedCountryIndex = sortedCountries.findIndex(
    (country) => country.code === selectedCountryCode
  );

  // Remove the selected country from the sorted list
  const [selectedCountry] = sortedCountries.splice(selectedCountryIndex, 1);

  // Insert the selected country at the beginning
  sortedCountries.unshift(selectedCountry);

  return sortedCountries;
}

export default function SelectCountryAutocomplete({
  isHighlighted,
  value,
  onChange,
}: SelectCountryAutocompleteProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const countryLabel = COUNTRIES.find((country) => country.code === value)?.label;
  const SORTED_COUNTRIES = useMemo(
    () => (value ? sortCountries(COUNTRIES, value) : COUNTRIES),
    [value]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleChange = (event: SyntheticEvent, value: Country | null) => {
    if (value) {
      onChange?.(value.code);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  return (
    <>
      <div className={styles.container}>
        <Button
          fullWidth
          disableRipple
          variant='outlined'
          aria-describedby={id}
          onClick={handleClick}
          className={classNames(
            styles.selectButton,
            open && styles.selectButtonActive,
            isHighlighted && styles.highlighted
          )}
          endIcon={<ThinChevronBottom />}
        >
          {countryLabel && value ? (
            <span className={styles.countryLabel}>
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${value.toLowerCase()}.png 2x`}
                className={"PhoneInputCountryIcon--border"}
              />
              {countryLabel}
            </span>
          ) : (
            "Select country"
          )}
        </Button>
        <Popper
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={styles.popper}
          placement='bottom-start'
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <StyledAutocomplete
                open
                disablePortal
                onChange={handleChange}
                className={styles.autocomplete}
                classes={{
                  popper: styles.autocompletePopper,
                  inputRoot: styles.searchInput,
                  inputFocused: styles.searchInputFocused,
                  paper: styles.autocompletePaper,
                  option: styles.option,
                }}
                popupIcon={false}
                onClose={(event: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                  if (reason === "escape") {
                    handleClose();
                  }
                }}
                options={SORTED_COUNTRIES}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props} className={classNames(styles.item, props.className)}>
                    <img
                      loading='lazy'
                      width='20'
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=''
                    />
                    <div className={styles.itemLabel}>
                      {option.label}
                      {option.code === value && <CheckmarkIcon />}
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    placeholder='Search countries'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
    </>
  );
}
