import { Col, Row, Card } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Options } from "./CreateAudit.constants";

import { Input } from "../../../../../components/ui-components";
import CardActions from "../../../../../Admin/pages/components/CardActions/CardActions";
import Popup from "../../../../../components/ui-components/Popup/Popup";
import CustomDropdown from "../../../../../components/ui-components/CustomDropdown/CustomDropdown";
import { useAudits } from "../../../../../hooks/services/useAudits";

const initError = {
  name: false,
  type: false,
};

const initAuditData = {
  name: "",
  type: "",
  account: "swiss",
};

const CreateAudit = ({ id, isOpen, presets, onSuccess, onClose }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { createAudit } = useAudits({ onAfterCreate: onSuccess });

  const [auditData, setAuditData] = useState(initAuditData);
  const [error, setError] = useState(initError);
  const [isLoading, setIsLoading] = useState(false);

  const translatedOptions = useMemo(() => {
    if (!presets) return [];

    setAuditData((prevState) => ({
      ...prevState,
      type: null,
    }));
    return Options(t, presets);
  }, [t, presets]);

  const isOtherType =
    auditData.type !== null &&
    translatedOptions.optionType.every((e) => !e.value || e.value !== auditData.type);

  const auditValue = !isOtherType ? auditData.type : "";

  const onSubmit = () => {
    setIsLoading(true);
    const { name, type } = auditData;
    const hasName = name?.length > 0;
    const hasType = type?.length > 0;
    if (hasName && hasType) {
      createAudit({ name, companyId: id, type });
      setAuditData(initAuditData);
      onClose?.();
    } else {
      setError({ name: !hasName, type: !hasType });
    }
    setIsLoading(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAuditData((prevState) => ({ ...prevState, [name]: value }));
    setError((state) => ({ ...state, [name]: false }));
  };

  const onSelect = (key, value) => {
    setError((state) => ({ ...state, [key]: false }));
    setAuditData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleClose = () => {
    setAuditData(initAuditData);
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={handleClose} title={t("create_audit")}>
      <Card.Grid hoverable={false} className={"popup-body"}>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("input_audit_title_label")}</span>
          </Col>
          <Col span={16}>
            <Input
              name='name'
              error={error.name}
              placeholder={t("input_audit_title_placeholder")}
              value={auditData.name}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("input_audit_type_label")}</span>
          </Col>
          <Col span={16}>
            <CustomDropdown
              name='type'
              options={translatedOptions.optionType}
              value={auditValue}
              error={error.type}
              onChange={onSelect}
              style={{ width: 250 }}
              headerStyle={{ height: 46 }}
            />
            {isOtherType && (
              <>
                <br />
                <Input
                  name='type'
                  error={error.type}
                  placeholder={t("specify_audit_type")}
                  value={auditData.type}
                  onChange={onChange}
                />
              </>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("input_accounting_standard_label")}</span>
          </Col>
          <Col span={16}>
            <CustomDropdown
              name='account'
              options={translatedOptions.optionAccount}
              value={auditData.account}
              onChange={onSelect}
              style={{ width: 250 }}
              headerStyle={{ height: 46 }}
            />
          </Col>
        </Row>
      </Card.Grid>
      <CardActions
        submitDisabled={isLoading}
        cancelText={tGlobal("cancel")}
        submitText={tGlobal("create")}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Popup>
  );
};

export default CreateAudit;
