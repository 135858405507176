import { Col, Drawer, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { updateStep } from "../../../../../../api/statusApi";
import useQueryParams from "../../../../../../hooks/useQueryParams";

import { Button } from "../../../../../../components/ui-components";
import ContentByType from "./ContentByType/ContentByType";
import Assertion from "../../../../../../components/ui-components/Assertion/Assertion";
import StatusDropdown from "../../../../../../components/StatusDropdown";

import { ArrowLeftIcon, CheckmarkIcon } from "../../../../../../assets/icons";

import styles from "../StatusView.module.scss";

const statuses = [
  { key: "not_started", color: "default" },
  { key: "in_progress", color: "blue" },
  { key: "pending", color: "yellow" },
  { key: "blocked", color: "red" },
  { key: "done", color: "green" },
];

const OpenedStep = ({
  openedStep,
  setOpenedStep,
  hasAdminPermissions,
  handleMarkAsDone,
  requests,
  meetings,
  documents,
  idAudit,
  setData,
  allowSubtask,
  parentName,
  setParentStep,
  noAnimation,
}) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [openedSubtask, setOpenedSubtask] = useState(null);
  const isPending = openedStep?.status === "pending";
  const { query, setQueryParams, removeQueryParams } = useQueryParams();

  function handleUpdateStatus(status) {
    handleMarkAsDone(openedStep.id, !allowSubtask, status);
  }

  const handleOpenSubtask = (subtask) => {
    if (!subtask) return;
    setOpenedSubtask(subtask);
    setQueryParams({ openedSubtask: subtask.id });
  };

  const saveChanges = async (data, customOpenedStepId) => {
    const openStepId = openedStep?.id || customOpenedStepId;
    setParentStep?.((state) =>
      state
        ? {
            ...state,
            subtasks: state.subtasks.map((subtask) => {
              if (subtask.id === openStepId) {
                return {
                  ...subtask,
                  ...data,
                };
              }
              return subtask;
            }),
          }
        : undefined
    );
    await updateStep(idAudit, openStepId, data);
  };

  const markSubtaskAsDone = (i, allowSubtask = true, status) => {
    handleMarkAsDone(i, true, status);
    setOpenedSubtask(null);
  };

  const closeAllDrawers = () => {
    setParentStep?.(null);
    setOpenedStep(null);
    removeQueryParams(["openedStep", "openedSubtask"]);
  };

  useEffect(() => {
    if (query.openedSubtask && openedStep?.subtasks) {
      handleOpenSubtask(openedStep.subtasks.find((i) => i.id === +query.openedSubtask));
    }
  }, [openedStep?.subtasks?.length]);

  return (
    <>
      {allowSubtask && (
        <OpenedStep
          openedStep={openedSubtask}
          setOpenedStep={setOpenedSubtask}
          hasAdminPermissions={hasAdminPermissions}
          handleMarkAsDone={markSubtaskAsDone}
          requests={requests}
          meetings={meetings}
          documents={documents}
          idAudit={idAudit}
          setData={setData}
          parentName={openedStep?.name}
          setParentStep={setOpenedStep}
          noAnimation={noAnimation}
        />
      )}
      <Drawer
        open={!!openedStep}
        rootClassName={`${noAnimation ? styles.noAnimation : ""} edit-step-drawer`}
        onClose={closeAllDrawers}
      >
        {!!openedStep && (
          <div className={styles.drawerContent}>
            {!allowSubtask && (
              <div className={styles.subtaskHeader} style={{ padding: "16px 24px 12px 16px" }}>
                <ArrowLeftIcon onClick={() => setOpenedStep(null)} />
                <span>{parentName}</span>
              </div>
            )}
            <div className={styles.drawerHead}>
              <span className={styles.type}>{openedStep.type.split("_").join(" ")}</span>
            </div>
            <div className={styles.mainInfo}>
              <Row justify='space-between' wrap={false} align='middle' gutter={16}>
                <Col>
                  <span className={styles.drawerTitle}>{openedStep.name}</span>
                </Col>
                <Col>
                  {hasAdminPermissions ? (
                    <StatusDropdown
                      statuses={statuses}
                      value={openedStep.status}
                      onChange={handleUpdateStatus}
                    />
                  ) : (
                    <Button
                      primary
                      style={{ padding: "5px 8px", gap: 10 }}
                      color={isPending ? "green" : "lightGreen"}
                      disabled={openedStep.status === "done"}
                      handleClick={() => handleUpdateStatus(isPending ? "in_progress" : "pending")}
                    >
                      <CheckmarkIcon />
                      {isPending ? tGlobal("done") : tGlobal("mark_as_done")}
                    </Button>
                  )}
                </Col>
              </Row>
              {openedStep.ownership === "auditor" && openedStep?.assertions?.length > 0 && (
                <div className={styles.assertionRow}>
                  {openedStep.assertions
                    ?.sort((a, b) => a.id - b.id)
                    .map((assertion, index) => (
                      <Assertion id={assertion.id} key={index}>
                        {assertion.title}
                      </Assertion>
                    ))}
                </div>
              )}
            </div>
            <div className={styles.drawerBody}>
              <div className={styles.field}>
                <label>{tGlobal("description")}</label>
                <span dangerouslySetInnerHTML={{ __html: openedStep.description }} />
              </div>
              <ContentByType
                requests={requests}
                meetings={meetings}
                documents={documents}
                openedStep={openedStep}
                setOpenedStep={setOpenedStep}
                idAudit={idAudit}
                setData={setData}
                saveChanges={saveChanges}
                setOpenedSubtask={handleOpenSubtask}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default OpenedStep;
