import { Popover } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { SearchIcon } from "../../assets/icons";

import { Button, Input } from "../ui-components";

import styles from "./AttachDocuments.module.scss";

const AttachDocuments = (props) => {
  const {
    children,
    placeholder,
    values,
    optionComponent,
    options = [],
    restrictMode = {},
    isLoading,
    setIsOpen,
    onAddFile,
    onChange,
  } = props;

  const ref = useRef(null);
  const searchRef = useRef(null);
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [searchValue, setSearchValue] = useState("");
  const [isPopoverVisible, setIsPopoverVisible] = useState(false); // State to control popover visibility
  const { getInputProps, open } = useDropzone({
    noClick: true,
    multiple: true,
    accept: restrictMode,
    disabled: isLoading,
    onDropAccepted: !isLoading && onAddFile,
  });

  useEffect(() => {
    searchRef.current && searchRef.current.focus();
  }, []);

  const handleOptionClick = (option) => {
    if (onChange) {
      onChange(option);
      setIsOpen?.(false);
      setIsPopoverVisible(false); // Close the popover after selecting an option
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          [option.document, option.name, option.title].some((i) =>
            i?.toLowerCase().includes(searchValue.toLowerCase())
          ) && !values.some((i) => i.id === option.id)
      ),
    [options, searchValue, values]
  );

  return (
    <Popover
      ref={ref}
      trigger='click'
      placement='bottom'
      id='attach-documents'
      open={isPopoverVisible}
      onOpenChange={setIsPopoverVisible}
      rootClassName={styles.popover}
      content={
        <div className={styles.dropdownList}>
          <div className={styles.pointer} />
          <div className={styles.dropdownHeader}>
            <SearchIcon className={styles.searchIcon} />
            <Input
              ref={searchRef}
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={placeholder}
            />
          </div>
          <ul className={styles.options}>
            {filteredOptions.length ? (
              filteredOptions.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)} style={option.style}>
                  {optionComponent(option)}
                </li>
              ))
            ) : (
              <li className={styles.noResults}>
                {searchValue ? tGlobal("no_results") : t("no_documents")}
              </li>
            )}
          </ul>
          <div className={styles.footerActions}>
            <input
              type='file'
              name='attachments'
              hidden
              onChange={onAddFile}
              {...getInputProps()}
            />
            <Button fullWidth handleClick={open} color={"blue"} secondary>
              {tGlobal("upload_new")}
            </Button>
          </div>
        </div>
      }
    >
      <div onClick={() => setIsPopoverVisible(true)}>{children}</div> {/* Open popover on click */}
    </Popover>
  );
};

export default AttachDocuments;
