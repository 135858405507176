import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CheckData from "./CheckData";
import AssignSearch from "./AssignSearch";
import AvatarBlock from "../Admin/pages/Requests/components/NewRequest/AvatarBlock/AvatarBlock";
import { AssignButton } from "../Admin/pages/components";
import Attachments from "../Admin/pages/components/Attachments/Attachments";
import CustomSwitch from "./ui-components/Switch/Switch";
import toFullName from "../consts/toFullName";

import "./styles/newDocument.css";
import { useDocuments } from "../hooks/services/useDocuments";
import { DocumentType } from "../enums";
import FormPicker from "../Admin/pages/components/FormPicker/FormPicker";
import DropzoneUpload from "../pages/dashboard/Documents/components/DropzoneUpload/DropzoneUpload";
import { categoryOptions } from "../pages/dashboard/Documents/Documents.constant";

const NewDocument = ({ close, activeTab }) => {
  const { id: idAudit } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });

  const user = useSelector((state) => state.user.user_data);
  const userRole = useSelector((state) => state.user.user_role);

  const [files, setFiles] = useState([]);
  const [assignOpen, setAssignOpen] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [name, setName] = useState(false);
  const [activeCategory, setActiveCategory] = useState(
    activeTab === "all" || activeTab === "unlinked" ? DocumentType.evidence : activeTab
  );
  const [activeData, setActiveData] = useState(false);
  const [signMyself, setSignMyself] = useState(false);
  const [error, setError] = useState({
    name: false,
    file: false,
  });
  const { createDocument } = useDocuments();

  const isOrganiser = (email) => email === user.email;

  const onSubmit = () => {
    const signers = assignedUsers.map((i) => ({
      memberId: i.member?.id || (!i.auditor ? i.id : null),
      auditorId: i.auditor?.id,
    }));

    if (signMyself) {
      signers.push({
        memberId: userRole.member?.id || (!userRole.auditor ? userRole.id : null),
        auditorId: userRole.auditor?.id,
      });
    }
    if (activeData) {
      createDocument({
        body: {
          name: name,
          document: files[0],
          signers: JSON.stringify(signers),
          type: activeCategory || DocumentType.evidence,
        },
        auditId: idAudit,
      });
      close(false);
    } else {
      setError({
        name: !name,
        file: !files,
      });
    }
  };

  const removeAssignee = (id) => {
    setAssignedUsers(assignedUsers.filter((item) => item.id !== id));
  };

  const handleAssignOpen = () => {
    if (!assignOpen) {
      setAssignOpen(true);
    }
  };

  const handleAssignedUsers = ({ assignedUsers }) => {
    setAssignedUsers(assignedUsers);
  };

  const handleChangeSwitch = () => {
    setSignMyself((prev) => !prev);
  };

  const onFileDrop = (files) => {
    setFiles([files[0]]);
    setError((prevState) => ({
      ...prevState,
      file: false,
    }));
  };

  useEffect(() => {
    setActiveData(name.length > 0 && files.length > 0);
    // eslint-disable-next-line
  }, [name, files]);

  return (
    <div className='sub_menu_document'>
      <DropzoneUpload className='settings_right-audit' onDrop={onFileDrop} multiple>
        <span className='popup-title'>{t("upload_document")}</span>
        <hr className='documents_line-create' />
        <div className='block-input'>
          <div className='block-row'>
            <span className='title-input'>{t("upload_file")}</span>
            <div className='category-line description attachments'>
              <Attachments
                newDocument
                multiple={false}
                attachments={files}
                onAddFile={onFileDrop}
                onRemoveFile={(deletedId) => {
                  setFiles((prev) => prev.filter((f) => f.id !== deletedId));
                }}
                noLabel
              />
            </div>
          </div>
          <div className='block-row'>
            <span className='title-input'>{t("filename")}</span>
            <input
              className={`${error.name ? "errorActive" : "inputNewDocuments"} `}
              placeholder='e.g. Angebot'
              onChange={({ target }) => {
                setName(target.value);
                setError((prevState) => ({
                  ...prevState,
                  name: false,
                }));
              }}
            />
          </div>

          <div className='block-row'>
            <span className='title-input'>{t("category")}</span>
            <FormPicker
              options={categoryOptions.map((i) => ({ name: t(i), value: i }))}
              selected={activeCategory}
              onSelect={(_, value) => setActiveCategory(value)}
            />
          </div>

          <div className='block-row'>
            <span className='title-input'>{t("require_signatures")}</span>
            <div className={"require-signature-block"}>
              <div className={"require-signature-row"}>
                {assignedUsers
                  .filter((u) => u.id !== userRole.id)
                  .map((assignedUser) => (
                    <AvatarBlock
                      removable
                      onRemove={() => removeAssignee(assignedUser.id)}
                      name={toFullName(assignedUser.user)}
                      user={assignedUser.user}
                    />
                  ))}
                <AssignButton handleAssignOpen={handleAssignOpen} />
              </div>
              <div className={"upload-document-signer"}>
                <AssignSearch
                  isOpen={assignOpen}
                  onClose={() => {
                    setAssignOpen(false);
                  }}
                  setAssignId={() => {}}
                  isOrganiser={isOrganiser}
                  setAssignTitle={handleAssignedUsers}
                  hideGroups
                  addAssign={{
                    assignedRoles: [],
                    assignedUsers: assignedUsers,
                  }}
                />
              </div>
            </div>
          </div>
          <div className='block-row' style={{ alignItems: "center" }}>
            <span className='title-input'>{t("my_signature")}</span>
            <div className={"require-signature-block"}>
              <CustomSwitch
                checked={signMyself}
                onChange={handleChangeSwitch}
                labelPlacement='end'
                label={t("sign_by_my_self")}
              />
            </div>
          </div>
        </div>
        <hr className='documents_line-create' />
        <div className='addAuditBtn'>
          <CheckData
            discard={() => {
              close(false);
            }}
            onClick={onSubmit}
            text={t("upload")}
            activeBtn={activeData}
          />
        </div>
      </DropzoneUpload>
    </div>
  );
};

export default NewDocument;
