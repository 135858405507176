import { useCallback, useEffect, useState } from "react";

type ResizeHookResult = {
  innerWidth: number;
};

export default function useResize(): ResizeHookResult {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  const handleResize = useCallback(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { innerWidth };
}
